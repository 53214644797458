import React, { useState } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import { useToast } from '@chakra-ui/toast';
import { convertJSONtoFormData } from '../../helper/_commonHelper';
import AddEditTranslations from '../translations/AddEditTranslations';
import CreateDescription from './masterTextEditor';
import { Switch, FormControl, FormLabel } from '@chakra-ui/react';

const AddEditMenu = ({ title, open, data, setShow, refresh, showDescriptionField }) => {
    const [formData, setFormData] = useState({
        name: data?.name || '',
        description: data?.description || '',
        type: data?.type || 'default',
        image: data?.image || undefined,
        disclaimer: data?.disclaimer || '',
        priority: data?.priority || 0,
        start_time: data?.start_time || '',
        end_time: data?.end_time || '',
        translations: data?.translations || [],
        view_only: data?.view_only || 0,
    });
    const [errors, setError] = useState(null);
    const toast = useToast();
    const [rows, setRows] = useState([]);

    const handleChange = (evt) => {
        let value = null;
        if (evt.target.type === 'checkbox') {
            value = evt.target.checked;
        } else if (evt.target.type === 'file') {
            value = evt.target.files[0];
        } else {
            value = evt.target.value;
        }
        setFormData({
            ...formData,
            [evt.target.name]: value,
        });
    };

    const saveForm = async () => {
        if (data?.id) {
            delete formData.type;
            if (formData.image && typeof formData.image !== 'object') {
                delete formData?.image;
            }
        }
        try {
            let updatedRows = rows.map((row) => ({
                hotel_language_id: row.hotel_language_id,
                name: row.name,
                description: row.description,
                disclaimer: row.disclaimer
            }));
            formData['translations'] = updatedRows;
            const datatoSend = convertJSONtoFormData(formData);

            let url =
                (data?.id ? '/outlet/update/menu' : '/outlet/menu') +
                (data?.id ? '/' + data?.id : '');
            let response = await axios.post(url, datatoSend);

            if (response.status === 201 || response.status === 202) {
                const result = response.data || {};
                const action = data?.id ? 'Updated' : 'Created';
                showToast(`Menu ${action}!`, result?.message);
                setShow(false);
                refresh();
            } else if (response.status === 400) {
                const result = response.data || {};
                const keys = Object.keys(result?.message);
                let errs = keys?.map((key) => {
                    return {
                        field: key,
                        error: result?.message?.[key]?.[0],
                    };
                });
                setError(errs);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const submit = async () => {
        await saveForm();
    };
    const deleteImage = async () => {
        if (window.confirm('Do you want to delete this image?') == false) {
            return;
        }
        try {
            const payload = {
                entity_type: 'menu',
                entity_id: data?.id,
            };
            const response = await axios.delete('/image/image', {
                data: payload,
            });
            if (response.status === 200) {
                setFormData({
                    ...formData,
                    image: undefined,
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const showToast = (title, description) => {
        toast({
            title: title || 'Menu Updated!',
            description:
                `${description}!` || 'Menu change has been done successfully!!',
            status: 'success',
            duration: 2000,
            isClosable: true,
        });
    };

    
    const handleViewChange = (e) => {
        setFormData({
          ...formData,
          view_only: Number(e.target.checked),
        });
      };
    return (
        <Modal
            isOpen={open}
            onRequestClose={() => setShow(false)}
            className={'modal-dialog modal-lg modal-order-details'}
            overlayClassName={'modal-backdrop overflow-auto '}
            contentLabel='Example Modal'
        >
            <div
                className='modal-content'
                aria-modal='true'
                id='modal-content-block'
            >
                <div className='modal-header'>
                    <div className='title'>
                        {' '}
                        {data?.id ? 'Update ' + title : 'Add ' + title}
                    </div>
                    <button
                        className='btn btn-icon'
                        onClick={() => setShow(false)}
                    >
                        <i className='fa fa-times'></i>
                    </button>
                </div>

                <div className='modal-body'>
                    {/* FORM */}
                    <div className='form-group'>
                        <label htmlFor='exampleInputEmail1'>Name</label>
                        <input
                            type='text'
                            className='form-control'
                            placeholder='Name'
                            name='name'
                            value={formData.name}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='form-group' id="desc">
                        <label htmlFor='exampleInputEmail1'>
                            {/* Disclaimer (this text will appear on the i icon on
                            top right of the menu) */}
                            Description
                        </label>
                        <CreateDescription description={formData.description} onChange={handleChange} />
                    </div>
                    {/* <div className="form-group">
              <label htmlFor="exampleInputEmail1">type</label>
              <input
                type="text"
                className="form-control"
                placeholder="type"
                name="type"
                value={formData.type}
                onChange={handleChange}
              />
            </div> */}
                    {
                        <div className='form-group row'>
                            <div className='col-sm-6'>
                                <label htmlFor='exampleInputEmail1'>
                                    Image
                                </label>
                                <input
                                    type='file'
                                    className='form-control'
                                    placeholder='image'
                                    name='image'
                                    // value={formData.image}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='col-sm-6'>
                                {formData?.image &&
                                    typeof formData?.image != 'object' && (
                                        <div className='image-wrapper'>
                                            <img
                                                src={`https://master-vserve.s3.ap-south-1.amazonaws.com/menus/${formData.image}`}
                                                alt='category'
                                                className='image'
                                            />
                                            <button
                                                className='btn btn-icon btn-danger'
                                                onClick={deleteImage}
                                            >
                                                <i className='fa fa-trash'></i>
                                            </button>
                                        </div>
                                    )}
                            </div>
                        </div>
                    }
                    {/* <div className="form-group">
              <label htmlFor="exampleInputEmail1">Disclaimer</label>
              <input
                type="text"
                className="form-control"
                placeholder="Disclaimer"
                name="disclaimer"
                value={formData.disclaimer}
                onChange={handleChange}
              />
            </div> */}
                    <div className='form-group'>
                        <label htmlFor='exampleInputEmail1'>Priority</label>
                        <input
                            type='number'
                            className='form-control'
                            placeholder='priority'
                            name='priority'
                            value={formData.priority}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='form-group'>
                        <label htmlFor='xampleInputEmail1'>Translations</label>
                        <AddEditTranslations
                            translations={formData.translations}
                            rows={rows}
                            setRows={setRows}
                            showDescriptionField={showDescriptionField}
                        />
                    </div>

                    <FormControl display="flex" alignItems="center" marginTop={'5'}>
                        <FormLabel htmlFor="menuType">
                            Orderable
                        </FormLabel>
                                <Switch
                                onChange={handleViewChange}
                                isChecked={formData.view_only === 1} // Assuming 1 represents View Only
                                name="view_only"
                                id={data.id || 1}
                                />
                        <FormLabel htmlFor="menuType" marginLeft={'4'}>
                            View Only
                        </FormLabel>
                    </FormControl>

                                        {/* <div className="form-group">
              <label htmlFor="exampleInputEmail1">start_time</label>
              <input
                type="time"
                className="form-control"
                placeholder="start_time"
                name="start_time"
                value={formData.start_time}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">end_time</label>
              <input
                type="time"
                className="form-control"
                placeholder="end_time"
                name="end_time"
                value={formData.end_time}
                onChange={handleChange}
              />
            </div> */}

                    <div className='text-danger'>
                        {errors &&
                            errors.map((err, index) => (
                                <div key={index}>
                                    {err?.field?.toUpperCase()} : {err?.error}
                                </div>
                            ))}
                    </div>
                </div>

                <div className='modal-footer'>
                    <button className='btn' onClick={() => setShow(false)}>
                        Close without saving
                    </button>
                    <button
                        className='btn btn-primary'
                        onClick={() => submit()}
                    >
                        Save and close
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default AddEditMenu;
