import React from "react";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";

export const SearchOrder = (props) => {
  const { inputHandleChange, searchValue } = props;

  return (
    <InputGroup>
      <InputLeftElement pointerEvents="none">
        <SearchIcon color="gray.300" />
      </InputLeftElement>
      <Input
        type="text"
        value={searchValue}
        placeholder="Search for Order Id"
        style={{ width: "15rem", backgroundColor:"white" }}
        onChange={(e) => inputHandleChange(e)}
      />
    </InputGroup>
  );
};
