import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import Loading from "../common/Loading";
import { Container } from "@chakra-ui/layout";
import axios from "axios";
import { useToast } from "@chakra-ui/toast";
import { useSelector } from "react-redux";
import { commonSelector } from "../../store/slices/common/commonSlice";
import { getCurrencySymbol } from "../../helper/_commonHelper";
import Allergens from "./Allergens";


const Menu = () => {
  const [orderMenuList, setOrderMenuList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { userProfile } = useSelector(commonSelector);

  const toast = useToast();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      let response = await axios.get("/outlet/menu");
      if (response.status === 200) {
        const data = response.data.data || [];
        setOrderMenuList(data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleMenuChecked = async (e) => {
    let id = e.target.id;
    let checkId = e.currentTarget.id;
    let checked = e.target.checked;
    if (checkId === id) {
      if (checked === false) {
        try {
          let response = await axios.put("/outlet/menu/disable/" + checkId);
          if (response.status === 202) {
            const data = response.data || {};
            fetchData();
            showToast("", data.message);
          }
        } catch (error) {
          console.log(error);
        }
      } else if (checked === true) {
        try {
          let response = await axios.put("/outlet/menu/enable/" + checkId);
          if (response.status === 202) {
            const data = response.data || {};
            fetchData();
            showToast("", data.message);
          }
        } catch (error) {
          console.log("error", error);
        }
      }
    }
  };

  const handleCategoryClick = async (e) => {
    let id = e.target.id;
    let checkId = e.currentTarget.id;
    let checked = e.target.checked;
    if (checkId === id) {
      if (checked === false) {
        try {
          let response = await axios.put(
            "/outlet/menu/category/disable/" + checkId
          );
          if (response.status === 202) {
            const data = response.data || {};
            fetchData();
            showToast("", data.message);
          }
        } catch (error) {
          console.log("error", error);
        }
      } else if (checked === true) {
        try {
          let response = await axios.put(
            "/outlet/menu/category/enable/" + checkId
          );
          if (response.status === 202) {
            const data = response.data || {};
            fetchData();
            showToast("", data.message);
          }
        } catch (error) {
          console.log("error", error);
        }
      }
    }
  };

  const handleSubCategoryClick = async (e) => {
    let id = e.target.id;
    let checkId = e.currentTarget.id;
    let checked = e.target.checked;

    if (checkId === id) {
      if (checked === false) {
        try {
          let response = await axios.put(
            "/outlet/menu/sub-category/disable/" + checkId
          );

          if (response.status === 202) {
            const data = response.data || {};
            fetchData();
            showToast("", data.message);
          }
        } catch (error) {
          console.log("error", error);
        }
      } else if (checked === true) {
        try {
          let response = await axios.put(
            "/outlet/menu/sub-category/enable/" + checkId
          );
          if (response.status === 202) {
            const data = response.data || {};
            fetchData();
            showToast("", data.message);
          }
        } catch (error) {
          console.log("error", error);
        }
      }
    }
  };

  const handleItemChecked = async (e) => {
    let id = e.target.id;
    let checkId = e.currentTarget.id;
    let checked = e.target.checked;
    if (checkId === id) {
      if (checked === false) {
        try {
          let response = await axios.put(
            "/outlet/menu/item/disable/" + checkId
          );
          if (response.status === 202) {
            const data = response.data || {};
            fetchData();
            showToast("", data.message);
          }
        } catch (error) {
          console.log("error", error);
        }
      } else if (checked === true) {
        try {
          let response = await axios.put("/outlet/menu/item/enable/" + checkId);
          if (response.status === 202) {
            const data = response.data || {};
            fetchData();
            showToast("", data.message);
          }
        } catch (error) {
          console.log("error", error);
        }
      }
    }
  };

  const handleAddonChecked = async (e) => {
    let id = e.target.id;
    let checkId = e.currentTarget.id;
    let checked = e.target.checked;
    if (checkId === id) {
      if (checked === false) {
        try {
          let response = await axios.put(
            "/outlet/menu/item/addon/disable/" + checkId
          );
          if (response.status === 202) {
            const data = response.data || {};
            fetchData();
            showToast("", data.message);
          }
        } catch (error) {
          console.log("error", error);
        }
      } else if (checked === true) {
        try {
          let response = await axios.put(
            "/outlet/menu/item/addon/enable/" + checkId
          );
          if (response.status === 202) {
            const data = response.data || {};
            fetchData();
            showToast("", data.message);
          }
        } catch (error) {
          console.log("error", error);
        }
      }
    }
  };
  const handleSubAddonChecked = async (e) => {
    let id = e.target.id;
    let checkId = e.currentTarget.id;
    let checked = e.target.checked;
    if (checkId === id) {
      if (checked === false) {
        try {
          let response = await axios.put(
            "/outlet/menu/item/sub-addon/disable/" + checkId
          );
          if (response.status === 202) {
            const data = response.data || {};
            fetchData();
            showToast("", data.message);
          }
        } catch (error) {
          console.log("error", error);
        }
      } else if (checked === true) {
        try {
          let response = await axios.put(
            "/outlet/menu/item/sub-addon/enable/" + checkId
          );
          if (response.status === 202) {
            const data = response.data || {};
            fetchData();
            showToast("", data.message);
          }
        } catch (error) {
          console.log("error", error);
        }
      }
    }
  };

  const showToast = (title, description) => {
    toast({
      title: title || "Menu Updated!",
      description:
        `${description}!` || "Menu change has been done successfully!!",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };


  const hotelCurrencyCode = userProfile.hotel?.country?.currency_code || "";
  const currencySymbol = getCurrencySymbol(hotelCurrencyCode);

  return (
    <div className="m-enable-wrapper" style={{ minHeight: "90vh" }}>
      <Container maxW="container.2xl">
        {loading ? (
          <Loading />
        ) : orderMenuList.length > 0 ? (
          <div className="main-section">
          <div id="accordion">

            {orderMenuList.map((itemMenu, index) => {
              return (
                <div className="m-card " key={itemMenu.id}>
                  <div className="m-card-header" id={"heading" + index}>
                    <div
                      className="m-title"
                      data-toggle="collapse"
                      data-target={"#collapseOne" + index}
                      aria-expanded="true"
                      aria-controls={"collapseOne" + index}
                    >
                      {itemMenu.name} ({itemMenu.categories.length})
                      <div className="float-right">
                        <Form>
                          <Form.Check
                            type="switch"
                            onChange={handleMenuChecked}
                            checked={itemMenu.enabled}
                            label=""
                            id={itemMenu.id}
                          />
                        </Form>
                      </div>
                    </div>
                  </div>
                  <div
                    id={"collapseOne" + index}
                    className="collapse"
                    aria-labelledby="headingOne"
                    data-parent="#accordion"
                    key={itemMenu.id}
                  >
                    <div className="card-body" id="category">
                      <div key={itemMenu.id}>
                        {itemMenu.description && (
                          <div
                            className="m-desp"
                            dangerouslySetInnerHTML={{
                              __html: itemMenu.description,
                            }}
                          ></div>
                        )}
                        {itemMenu.categories.map((itemMenuCategory, index) => {
                          return (
                            <div key={itemMenuCategory.id} className="">
                              <div
                                className="m-title"
                                data-toggle="collapse"
                                data-target={"#collapseCategory" + index}
                                id={"category" + index}                              >
                                {itemMenuCategory.name}
                                {/* ({itemMenuCategory.sub_categories.length}) */}
                                {itemMenuCategory.description && (
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: itemMenuCategory.description,
                                    }}
                                  ></span>
                                )}
                                <div className="float-right asdasda">
                                  <Form>
                                    <Form.Check
                                      type="switch"
                                      onChange={handleCategoryClick}
                                      checked={
                                        itemMenu.enabled &&
                                        itemMenuCategory.enabled
                                      }
                                      disabled={!itemMenu.enabled}
                                      label=""
                                      id={itemMenuCategory.id}
                                    />
                                  </Form>
                                </div>
                              </div>
                              <div
                                className="collapse"
                                data-parent={"#category" + index} 
                                id={"collapseCategory" + index}
                              >
                                {itemMenuCategory.sub_categories.length > 0 &&
                                  itemMenuCategory.sub_categories.map(
                                    (itemSubCategoryMenuItem, index) => {
                                      return (
                                        <div
                                          className="m-content-card"
                                          key={itemSubCategoryMenuItem.id}
                                        >
                                          <div className="m-title">
                                            {index + 1}.{" "}
                                            {itemSubCategoryMenuItem.name} (
                                            {
                                              itemSubCategoryMenuItem.items
                                                .length
                                            }
                                            )
                                            <div className="float-right">
                                              <Form.Check
                                                type="switch"
                                                onChange={
                                                  handleSubCategoryClick
                                                }
                                                checked={
                                                  itemMenu.enabled &&
                                                  itemMenuCategory.enabled &&
                                                  itemSubCategoryMenuItem.enabled
                                                }
                                                disabled={
                                                  !(
                                                    itemMenu.enabled &&
                                                    itemMenuCategory.enabled
                                                  )
                                                }
                                                label=""
                                                id={itemSubCategoryMenuItem.id}
                                              />
                                            </div>
                                          </div>
                                          {itemSubCategoryMenuItem.items
                                            .length > 0 &&
                                            itemSubCategoryMenuItem.items.map(
                                              (menuItem) => {
                                                return (
                                                  <div
                                                    key={menuItem.id}
                                                    className="m-item-card"
                                                  >
                                                    <span
                                                      style={{
                                                        marginRight: "2px",
                                                        fontSize: "14px",
                                                      }}
                                                      title="Item"
                                                    >
                                                      {menuItem.type ===
                                                        "veg" && (
                                                          <img
                                                            src="../../../assets/images/veg.png"
                                                            width="16px"
                                                            alt=""
                                                            srcSet=""
                                                          />
                                                        )}
                                                      {menuItem.type ===
                                                        "non_veg" && (
                                                          <img
                                                            src="../../../assets/images/non-veg.png"
                                                            width="16px"
                                                            alt=""
                                                            srcSet=""
                                                          />
                                                        )}
                                                      {menuItem.name}
                                                      <div className="float-right">
                                                        <Form.Check
                                                          type="switch"
                                                          onChange={
                                                            handleItemChecked
                                                          }
                                                          disabled={
                                                            !(
                                                              itemMenu.enabled &&
                                                              itemMenuCategory.enabled &&
                                                              itemSubCategoryMenuItem.enabled
                                                            )
                                                          }
                                                          checked={
                                                            itemMenu.enabled &&
                                                            itemMenuCategory.enabled &&
                                                            itemSubCategoryMenuItem.enabled &&
                                                            menuItem.enabled
                                                          }
                                                          label=""
                                                          id={menuItem.id}
                                                        />
                                                      </div>
                                                    </span>
                                                    {menuItem.price ? (
                                                      <h5 className="m-price">
                                                        {currencySymbol}
                                                        {menuItem.price}
                                                        .00
                                                      </h5>
                                                    ) : null}

                                                    <div
                                                      className="m-desp"
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          menuItem.description,
                                                      }}
                                                    ></div>
                                                    {menuItem.sub_addons
                                                      .length > 0 && (
                                                        <div
                                                          className="m-title"
                                                          data-toggle="collapse"
                                                          data-target={
                                                            "#collapseAddons" +
                                                            index
                                                          }
                                                          id={"addonsTitle" + index} // Use a unique identifier for the title
                                                        >
                                                          Addons
                                                        </div>
                                                      )}
                                                    {menuItem.sub_addons
                                                      .length > 0 &&
                                                      menuItem.sub_addons.map(
                                                        (subNewItem) => {
                                                          return (
                                                            <div
                                                              className="m-content-card collapse"
                                                              data-parent={"#addonsTitle" + index} // Use the corresponding title's id as the parent
                                                              id={
                                                                "collapseAddons" +
                                                                index
                                                              }
                                                              key={
                                                                subNewItem.id
                                                              }
                                                            >
                                                              <div
                                                                className="m-title"
                                                                title="Sub Addon"
                                                              >
                                                                {
                                                                  subNewItem.name
                                                                }
                                                                (
                                                                {
                                                                  subNewItem
                                                                    .addons
                                                                    .length
                                                                }
                                                                )
                                                                <div className="float-right">
                                                                  <Form>
                                                                    <Form.Check
                                                                      type="switch"
                                                                      onChange={
                                                                        handleSubAddonChecked
                                                                      }
                                                                      disabled={
                                                                        !(
                                                                          itemMenu.enabled &&
                                                                          itemMenuCategory.enabled &&
                                                                          itemSubCategoryMenuItem.enabled &&
                                                                          menuItem.enabled
                                                                        )
                                                                      }
                                                                      checked={
                                                                        itemMenu.enabled &&
                                                                        itemMenuCategory.enabled &&
                                                                        itemSubCategoryMenuItem.enabled &&
                                                                        menuItem.enabled &&
                                                                        subNewItem.enabled
                                                                      }
                                                                      label=""
                                                                      id={
                                                                        subNewItem.id
                                                                      }
                                                                    />
                                                                  </Form>
                                                                </div>
                                                              </div>
                                                              {subNewItem.addons
                                                                .length > 0 &&
                                                                subNewItem.addons.map(
                                                                  (
                                                                    addonsItem
                                                                  ) => {
                                                                    return (
                                                                      <div
                                                                        className="m-content-card"
                                                                        key={
                                                                          addonsItem.id
                                                                        }
                                                                      >
                                                                        <span
                                                                          title="Addon"
                                                                          style={{
                                                                            marginRight:
                                                                              "2px",
                                                                            fontSize:
                                                                              "14px",
                                                                          }}
                                                                        >
                                                                          {addonsItem.type ===
                                                                            "veg" && (
                                                                              <img
                                                                                src="../../../assets/images/veg.png"
                                                                                width="16px"
                                                                                alt=""
                                                                                srcSet=""
                                                                              />
                                                                            )}
                                                                          {addonsItem.type ===
                                                                            "non_veg" && (
                                                                              <img
                                                                                src="../../../assets/images/non-veg.png"
                                                                                width="16px"
                                                                                alt=""
                                                                                srcSet=""
                                                                              />
                                                                            )}{" "}
                                                                          {
                                                                            addonsItem.name
                                                                          }
                                                                          <div className="float-right">
                                                                            <Form>
                                                                              <Form.Check
                                                                                type="switch"
                                                                                onChange={
                                                                                  handleAddonChecked
                                                                                }
                                                                                checked={
                                                                                  itemMenu.enabled &&
                                                                                  itemMenuCategory.enabled &&
                                                                                  itemSubCategoryMenuItem.enabled &&
                                                                                  menuItem.enabled &&
                                                                                  subNewItem.enabled &&
                                                                                  addonsItem.enabled
                                                                                }
                                                                                disabled={
                                                                                  !(
                                                                                    itemMenu.enabled &&
                                                                                    itemMenuCategory.enabled &&
                                                                                    itemSubCategoryMenuItem.enabled &&
                                                                                    menuItem.enabled &&
                                                                                    subNewItem.enabled
                                                                                  )
                                                                                }
                                                                                label=""
                                                                                id={
                                                                                  addonsItem.id
                                                                                }
                                                                              />
                                                                            </Form>
                                                                          </div>
                                                                          { }
                                                                        </span>
                                                                        {addonsItem.price ? (
                                                                          <div className="m-price">
                                                                            {
                                                                              currencySymbol
                                                                            }
                                                                            {
                                                                              addonsItem.price
                                                                            }
                                                                            .00
                                                                          </div>
                                                                        ) : null}
                                                                      </div>
                                                                    );
                                                                  }
                                                                )}
                                                            </div>
                                                          );
                                                        }
                                                      )}
                                                  </div>
                                                );
                                              }
                                            )}
                                        </div>
                                      );
                                    }
                                  )}
                                {itemMenuCategory.without_sub_category_items
                                  .length > 0 &&
                                  itemMenuCategory.without_sub_category_items.map(
                                    (itemWithoutSubCategory) => {
                                      return (
                                        <div
                                          className="m-item-card"
                                          key={itemWithoutSubCategory.id}
                                        >
                                          <div className="float-right">
                                            <Form.Check
                                              type="switch"
                                              onChange={handleItemChecked}
                                              checked={
                                                itemMenuCategory.enabled &&
                                                itemMenu.enabled &&
                                                itemWithoutSubCategory.enabled
                                              }
                                              disabled={
                                                !(
                                                  itemMenu.enabled &&
                                                  itemMenuCategory.enabled
                                                )
                                              }
                                              label=""
                                              id={itemWithoutSubCategory.id}
                                            />
                                          </div>
                                          <span
                                            style={{
                                              marginRight: "2px",
                                              fontSize: "14px",
                                            }}
                                          >
                                            {itemWithoutSubCategory.type ===
                                              "veg" && (
                                                <img
                                                  src="../../../assets/images/veg.png"
                                                  width="16px"
                                                  alt=""
                                                  srcSet=""
                                                />
                                              )}
                                            {itemWithoutSubCategory.type ===
                                              "non_veg" && (
                                                <img
                                                  src="../../../assets/images/non-veg.png"
                                                  width="16px"
                                                  alt=""
                                                  srcSet=""
                                                />
                                              )}{" "}
                                            {itemWithoutSubCategory.name}
                                            <div className="float-right"></div>
                                            { }
                                          </span>
                                          {itemWithoutSubCategory.price ? (
                                            <div className="m-price">
                                              {currencySymbol}
                                              {itemWithoutSubCategory.price}
                                              .00
                                            </div>
                                          ) : null}
                                          <div
                                            className="m-desp"
                                            id="WithOutCatAddons"
                                          >
                                            {itemWithoutSubCategory.description && (
                                              <span
                                                className="m-desp"
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    itemWithoutSubCategory.description,
                                                }}
                                              ></span>
                                            )}
                                            {itemWithoutSubCategory.sub_addons
                                              .length > 0 && (
                                                <div
                                                  className="m-title"
                                                  data-toggle="collapse"
                                                  data-target={
                                                    "#collapseWithOutCatAddons" +
                                                    index
                                                  }
                                                  id={"WithOutCatAddons" + index}
                                                >
                                                  Addons
                                                </div>
                                              )}
                                            {itemWithoutSubCategory.sub_addons
                                              .length > 0 &&
                                              itemWithoutSubCategory.sub_addons.map(
                                                (subNewItem) => {
                                                  return (
                                                    <div
                                                      className="m-content-card collapse"
                                                      data-parent={"#WithOutCatAddons"+ index}
                                                      id={
                                                        "collapseWithOutCatAddons" +
                                                        index
                                                      }
                                                      key={subNewItem.id}
                                                    >
                                                      <div className="m-title-plain">
                                                        {subNewItem.name}(
                                                        {
                                                          subNewItem.addons
                                                            .length
                                                        }
                                                        )
                                                        <div className="float-right">
                                                          <Form>
                                                            <Form.Check
                                                              type="switch"
                                                              onChange={
                                                                handleSubAddonChecked
                                                              }
                                                              checked={
                                                                itemMenu.enabled &&
                                                                itemMenuCategory.enabled &&
                                                                itemWithoutSubCategory.enabled &&
                                                                subNewItem.enabled
                                                              }
                                                              label=""
                                                              id={subNewItem.id}
                                                              disabled={
                                                                !(
                                                                  itemMenu.enabled &&
                                                                  itemMenuCategory.enabled &&
                                                                  itemWithoutSubCategory.enabled
                                                                )
                                                              }
                                                            />
                                                          </Form>
                                                        </div>
                                                      </div>
                                                      {subNewItem.addons
                                                        .length > 0 &&
                                                        subNewItem.addons.map(
                                                          (addonsItem) => {
                                                            return (
                                                              <div
                                                                className="m-content-card"
                                                                key={
                                                                  addonsItem.id
                                                                }
                                                              >
                                                                <span
                                                                  style={{
                                                                    marginRight:
                                                                      "2px",
                                                                    fontSize:
                                                                      "14px",
                                                                  }}
                                                                >
                                                                  {addonsItem.type ===
                                                                    "veg" && (
                                                                      <img
                                                                        src="../../../assets/images/veg.png"
                                                                        width="16px"
                                                                        alt=""
                                                                        srcSet=""
                                                                      />
                                                                    )}
                                                                  {addonsItem.type ===
                                                                    "non_veg" && (
                                                                      <img
                                                                        src="../../../assets/images/non-veg.png"
                                                                        width="16px"
                                                                        alt=""
                                                                        srcSet=""
                                                                      />
                                                                    )}{" "}
                                                                  {
                                                                    addonsItem.name
                                                                  }
                                                                  <div className="float-right">
                                                                    <Form>
                                                                      {/* <Form.Check
                                                                      type="switch"
                                                                      label=""
                                                                    /> */}
                                                                      <Form.Check
                                                                        type="switch"
                                                                        onChange={
                                                                          handleAddonChecked
                                                                        }
                                                                        checked={
                                                                          itemMenu.enabled &&
                                                                          itemMenuCategory.enabled &&
                                                                          itemWithoutSubCategory.enabled &&
                                                                          addonsItem.enabled &&
                                                                          subNewItem.enabled
                                                                        }
                                                                        disabled={
                                                                          !(
                                                                            itemMenu.enabled &&
                                                                            itemMenuCategory.enabled &&
                                                                            itemWithoutSubCategory.enabled &&
                                                                            subNewItem.enabled
                                                                          )
                                                                        }
                                                                        label=""
                                                                        id={
                                                                          addonsItem.id
                                                                        }
                                                                      />
                                                                    </Form>
                                                                  </div>
                                                                  { }
                                                                </span>
                                                                {addonsItem.price ? (
                                                                  <div className="m-price">
                                                                    {
                                                                      currencySymbol
                                                                    }
                                                                    {
                                                                      addonsItem.price
                                                                    }
                                                                    .00
                                                                  </div>
                                                                ) : null}
                                                              </div>
                                                            );
                                                          }
                                                        )}
                                                    </div>
                                                  );
                                                }
                                              )}
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            
          </div>
          <Allergens/>
          </div>
          
        ) : null}
        
      </Container>
    </div>
  );
};

export default Menu;

