import React, { Fragment, useEffect, useState } from 'react'
import moment from 'moment'


const Timer = ({ countDown }) => {
    
    const [cTime, setTime] = useState(null)

    // let now = moment(new Date()).format("DD/MM/YYYY HH:mm:ss");
    // let then = moment(new Date(countDown)).format("DD/MM/YYYY HH:mm:ss");

    // let duration = moment.utc(moment(now, "DD/MM/YYYY HH:mm:ss").diff(moment(then, "DD/MM/YYYY HH:mm:ss"))).format("HH:mm:ss")

    

    const Tim = () => {
        let now = moment(new Date()).format("DD/MM/YYYY HH:mm:ss");
        let then = moment(new Date(countDown)).format("DD/MM/YYYY HH:mm:ss");

        let duration = moment(now, "DD/MM/YYYY HH:mm:ss").diff(moment(then, "DD/MM/YYYY HH:mm:ss"));
        // var da = moment.utc(duration).format("M");
        // var hrs = moment.utc(duration).format("HH");
        // var min = moment.utc(duration).format("mm");
        // var sec = moment.utc(duration).format("ss");
        // setTime([da,hrs, min, sec].join(':'));
        var d = moment.duration(duration);
        var s = Math.floor(d.asHours()) + moment.utc(duration).format(":mm:ss");
        setTime(s);
    }

    useEffect(()=> {
        let myInterval = setInterval(Tim, 1000);
        return ()=> {
            clearInterval(myInterval);
          };
    }, [countDown]);

    return (
        <Fragment>
            {cTime}
        </Fragment>
    )
}

export default Timer
