import React, { useState } from "react";
import OrderCard from "../common/OrderCard";
import OrderDetailsPopup from "../common/OrderDetailsPopup";
import Loading from "../common/Loading";
import TransitionLoader from "../common/TransitionLoader";
import axios from "axios";
import { useDispatch } from "react-redux";
import {
  fetchOrderList,
  updateActionTakenOnOrderID,
} from "../../store/slices/ird/irdSlice";


const NewIrdOrder = (props) => {
  const [showLoader, setShowLoader] = useState(false);
  const [showOrderDetailModal, setShowOrderDetailModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});
  const { loading, data } = props;
  const dispatch = useDispatch();
  

  const handleClosePopup = () => {
    setSelectedOrder({});
    setShowOrderDetailModal(false);
  };
  const openOrderPopup = (item) => {
    setSelectedOrder(item);
    setShowOrderDetailModal(true);
  };

  const handleAcceptSave = async (item) => {
    dispatch(updateActionTakenOnOrderID(item.order_detail.order_id));
    try {
      setShowLoader(true);
      let response = await axios.put(
        `/outlet/order/accept/${item.order_detail.order_id}`
      );
      if (response.status === 202) {
        setShowLoader(false);
        dispatch(fetchOrderList());
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setShowLoader(false);
    }
  };

  

  return (
    <section className="tab-wrapper">
      {loading ? (
        <Loading />
      ) : data.length === 0 ? (
        <div className="container">
          <div
            className=""
            style={{
              margin: "25vh auto",
              textAlign: "center",
              color: "#8e8a8a",
            }}
          >
            <p>No Orders found</p>
          </div>
        </div>
      ) : (
        <div className="container-fluid">
          <div className="row home-screen">
            {data.length > 0 &&
              data.map((item) => (
                <OrderCard
                  tabType={"NewOrder"}
                  key={item.id}
                  item={item}
                  viewDetails={() => openOrderPopup(item)}
                  footerBtn={() => handleAcceptSave(item)}
                  btnTitle={"Accept"}
                  timerTime={item?.order_detail?.created_at}
                />
              ))}
          </div>
        </div>
      )}
      {showOrderDetailModal && (
        <OrderDetailsPopup
          item={selectedOrder}
          open={showOrderDetailModal}
          close={handleClosePopup}
        />
      )}
      {showLoader && (
        <TransitionLoader previousStage={"New Order"} newStage={"Accepted"} />
      )}
    </section>
  );
};

export default NewIrdOrder;
