import { Box, Text } from '@chakra-ui/react';
import React from 'react';

const NoDataFound = ({ msg = 'No Data Found' }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      p={8}
      borderWidth={1}
      borderRadius="md"
      borderColor="gray.200"
    >
      <img
        src="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
        alt="Empty Illustration"
        style={{ height: 60 }}
      />
      <Text mt={4}>{msg}</Text>
    </Box>
  );
};

export default NoDataFound;
