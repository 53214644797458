import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useToast } from "@chakra-ui/toast";

import { getLastOrderIds, loginUser } from "../services/apiService";
import { getCookie, setCookie } from "../helper/_cookieHelper";
import Logo from "../assets/logo.png";
import { commonSelector } from "../store/slices/common/commonSlice";
import { useSelector } from "react-redux";

const LoginPage = () => {
  const { deviceToken } = useSelector(commonSelector);
  const [loading, setLoading] = useState(false);
  const [loginData, setLoginData] = useState({
    username: "",
    password: "",
    device_token: deviceToken,
    device_type: "web",
  });
  let navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();

  useEffect(() => {
    const state = location.state;
    if (state && state.msg === "failure") {
      toast({
        title: "Something went wrong!",
        description: "Please try again after sometime.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  }, []);

//   const oktaLogin = () => {
//     window.location.replace(process.env.REACT_APP_OCTA_SSO_LINK);
//   };

  const handleLoginClick = async () => {
    if (!loginData.username || !loginData.username.trim()) {
      toast({
        title: "Username is required!",
        description: "Please enter a valid Username!",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    if (!loginData.password || !loginData.password.trim()) {
      toast({
        title: "Password is required!",
        description: "Please enter a valid Password!",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    try {
      setLoading(true);
      if (deviceToken) {
        loginData["device_token"] = deviceToken;
      }
      const response = await loginUser(JSON.stringify(loginData));
      if (response.status === 200) {
        const data = response.data || {};
        setCookie("token", `${data.access_token}`, 1);

        const lastOrderResponse = await getLastOrderIds();
        if (lastOrderResponse.status === 200) {
          const orderData = lastOrderResponse.data.data || {};
          localStorage.setItem("__lastOrders", JSON.stringify(orderData));
        }
        navigate("/", { replace: true });
      }
    } catch (error) {
      console.error(error);
      toast({
        title: "Something went wrong!",
        description: "Please try again after sometime.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setLoginData({ ...loginData, [name]: value });
  };
  const token = getCookie("token");
  if (token) {
    navigate("/", { replace: true });
  }

  return (
    <div className="login-page">
      <div className="login-wrapper">
        <div
          className="app-logo "
          style={{ backgroundImage: `url(${Logo})` }}
        />
        <div className="login-box">
          <div className="login-title text-center">
            <div className="title">Welcome back,</div>
            <div className="subtitle">Please sign in to access your orders</div>
          </div>
          <input
            type="text"
            name="username"
            className="form-control"
            placeholder="Enter username"
            value={loginData.username}
            onChange={(e) => handleInputChange(e)}
          />
          <input
            type="password"
            name="password"
            className="form-control"
            placeholder="Enter Password"
            value={loginData.password}
            onChange={(e) => handleInputChange(e)}
          />
          <button
            className="btn btn-primary"
            onClick={handleLoginClick}
            disabled={loading}
          >
            {loading && <i className="pi pi-spin pi-spinner"></i>} Login
          </button>
          {/* <div className="or">OR</div> */}
          {/* <button
            className="btn btn-secondary"
            onClick={oktaLogin}
            disabled={loading}
            style={{marginBottom:"20px"}}
          >
            Continue with SSO
          </button> */}
          {/* <a   className="btn btn-secondary" href="https://test-vserve-api.valet2you.in/saml2/mpf/login">Login with Marriott SSO</a> */}
        </div>
        <div className="text-center mt-3" style={{ fontSize: "12px" }}>
          Copyright &copy; 2022 ViralOps.
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
