import { configureStore } from "@reduxjs/toolkit";
import irdSlice from "./slices/ird/irdSlice";
import commonSlice from "./slices/common/commonSlice";
import thunk from "redux-thunk";

const store = configureStore({
  reducer: {
    irdSlice,
    commonSlice,
    devTools: process.env.NODE_ENV !== "production",
    middleware: [thunk],
  },
});

export default store;
