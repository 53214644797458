import React from 'react';
import { useState, useEffect } from 'react';
import {
    Button,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Switch,
    Select,
    useDisclosure,
    Flex,
    Text,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    IconButton,
} from '@chakra-ui/react';

import NoDataFound from './NoDataFound';
import Loading from '../common/Loading';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { AllergensIcon, AvailableAllergens } from '../../constant/typecodes';
import { createAllergens } from '../../services/apiService';
import { useRef } from 'react';
import { useToast } from '@chakra-ui/toast';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { commonSelector } from '../../store/slices/common/commonSlice';

const Allergens = () => {
    const [allergensList, setAllergensList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedData, setSelectedData] = useState({});
    const [confirmationData, setConfirmationData] = useState({ id: null });
    const { userProfile, languageList } = useSelector(commonSelector);
    const [languages, setLanguages] = useState(languageList);
    const [rows, setRows] = useState([]);

    const {
        isOpen: isOpenDelete,
        onOpen: onOpenDelete,
        onClose: onCloseDelete,
    } = useDisclosure();
    const cancelRef = useRef();
    const toast = useToast();

    const [formData, setFormData] = useState({
        hotel_id: userProfile.hotel_id,
        name: '',
        icon: undefined,
        enabled: 1,
        translations: [],
    });

    useEffect(() => {
        updateLanguages();
    }, []);

    useEffect(() => {
        if (userProfile.hotel_id) {
            fetchAllergens(userProfile.hotel_id);
        }
    }, [userProfile.hotel_id]);
    useEffect(() => {
        if (showModal && selectedData) {
            const enabledFlag = selectedData.enabled ?? 1;
            const iconValue = selectedData?.icon?.trim().toLowerCase();
            setFormData({
                hotel_id: userProfile.hotel_id || null,
                name: selectedData?.name || '',
                enabled: Number(enabledFlag),
                icon: iconValue || undefined,
                translations: selectedData?.translations || [],
            });
            setRows(selectedData?.translations || [])
        }
    }, [showModal]);

    const showToast = (title, description) => {
        toast({
            title: title || 'Menu Updated!',
            description:
                `${description}!` || 'Menu change has been done successfully!!',
            status: 'success',
            duration: 2000,
            isClosable: true,
        });
    };
    const fetchAllergens = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`/outlet/allergens`);
            if (response.status === 200) {
                const data = response.data.data || [];
                setAllergensList(data);
                setFormData(data);
            }
        } catch (error) {
            console.log('Cannot fetch allergen contact admin' + error);
        } finally {
            setLoading(false);
        }
    };
    const deleteClickHandler = async (id) => {
        try {
            const response = await axios.delete(`outlet/allergen/${id}`);
            if (response.status === 200) {
                showToast('success', 'Allergen deleted successfully');
                fetchAllergens(userProfile.hotel_id);
            }
        } catch (error) {
            console.error('Delete request failed:', error);
            showToast('error', 'Something went wrong! please try again');
        }
    };
    const addClickHandler = async () => {
        try {
            let updatedRows = rows.map((row) => ({
                hotel_language_id: row.hotel_language_id,
                name: row.name,
            }));
            formData['translations'] = updatedRows;
            const response = await createAllergens(formData, selectedData.id);
            if (
                response.status === 202 ||
                response.status === 200 ||
                response.status === 201
            ) {
                showToast(
                    'success',
                    `Allergen ${
                        selectedData.id ? 'updated' : 'added'
                    } successfully`
                );
                setShowModal(false);
                setFormData(() => ({
                    ...formData,
                    name: '',
                    enabled: 1,
                    icon: undefined,
                    translations: [],
                }));
                fetchAllergens(userProfile.hotel_id);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleDeleteConfirmation = (id) => {
        setConfirmationData({ id });
        onOpenDelete();
    };

    const handleDeleteConfirm = () => {
        deleteClickHandler(confirmationData.id);
        onCloseDelete();
    };

    const handleChange = (e) => {
        let value = null;
        const name = e.target.name;
        const type = e.target.type;
        if (type === 'checkbox') {
            value = e.target.checked;
            if (name === 'enabled') {
                value = Number(value);
            }
        } else if (type === 'file') {
            value = e.target.files[0];
        } else {
            value = e.target.value;
        }
        setFormData({ ...formData, [name]: value });
    };

    const updateLanguages = () => {
        const list = rows.map((item) => +item.hotel_language_id);
        const newList = languages.filter(
            (item) => !list.includes(item.id)
        );
        setLanguages(newList);
    };

    const handleLanguageChange = (e, idx) => {
        const { name, value } = e.target;
        const updatedRows = [...rows];
        let newObj = {
            ...updatedRows[idx],
            [name]: value,
        };
        if (value && name === 'hotel_language_id') {
            let data = languages.find((d) => d.id == value);
            newObj['hotel_language_id'] = data.id;
            newObj['language_code'] = data.lang_code;
            newObj['language_name'] = data.lang_name;
        }
        updatedRows[idx] = newObj;
        setRows(updatedRows);
    };

    const handleDeleteLanguage = (idx) => {
        if (
            !window.confirm('Are you sure you want to remove this translation?')
        )
            return;
        const updatedRows = rows.filter((item, index) => index !== idx) || [];
        setRows(updatedRows);
    };

    const handleAddLanguage = () => {
        if (languages.length === 0) {
            alert('No available languages to add.');
            return;
        }
        const newItem = {
            hotel_language_id: '',
            name: '',
            language_name: '',
            language_code: '',
        };

        if (languages.length === 1) {
            newItem['hotel_language_id'] = languages[0].id;
            newItem['name'] = languages[0].name;
        }
        setRows([...rows,newItem]);
    };

    return (
        <div className='allergen-section'>
            <div className='addAllergen-section'>
                <div className='allergen-heading'>Allergens List</div>
                <Button
                    onClick={() => {
                        setShowModal(true);
                        setSelectedData({});
                    }}
                    style={{ display: 'block' }}
                >
                    Add New Allergen
                </Button>
            </div>

            <Flex direction='column' p={4}>
                {loading ? (
                    <Loading />
                ) : allergensList.length === 0 ? (
                    <NoDataFound />
                ) : (
                    <Flex direction='column' alignItems='center'>
                        {allergensList.map((item) => (
                            <Flex
                                key={item.id}
                                className={`country-card ${
                                    item.enabled ? 'enabled' : 'disabled'
                                }`}
                                p={4}
                                mb={4}
                                borderRadius='md'
                                borderWidth='1px'
                                alignItems='center'
                            >
                                <Flex direction='column' alignItems='center'>
                                    <span className='flag'>
                                        <img
                                            src={
                                                AllergensIcon[
                                                    item.icon
                                                        ?.trim()
                                                        ?.toLowerCase()
                                                ]
                                            }
                                            alt='icon'
                                            className='alergy-icon'
                                        />
                                        <Text>{item.name}</Text>
                                    </span>
                                </Flex>
                                <Flex ml='auto' alignItems='center'>
                                    <Button
                                        shape='circle'
                                        colorScheme='blue'
                                        mr={2}
                                        onClick={() => {
                                            setShowModal(true);
                                            setSelectedData(item);
                                        }}
                                    >
                                        <EditIcon />
                                    </Button>
                                    <Button
                                        shape='circle'
                                        colorScheme='red'
                                        onClick={() =>
                                            handleDeleteConfirmation(item.id)
                                        }
                                    >
                                        <DeleteIcon />
                                    </Button>
                                </Flex>
                            </Flex>
                        ))}
                    </Flex>
                )}

                {/* Delete confirmation dialog */}
                <AlertDialog
                    isOpen={isOpenDelete}
                    leastDestructiveRef={cancelRef}
                    onClose={onCloseDelete}
                >
                    <AlertDialogOverlay>
                        <AlertDialogContent>
                            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                Delete Confirmation
                            </AlertDialogHeader>

                            <AlertDialogBody>
                                Are you sure you want to delete this allergen?
                            </AlertDialogBody>

                            <AlertDialogFooter>
                                <Button ref={cancelRef} onClick={onCloseDelete}>
                                    Cancel
                                </Button>
                                <Button
                                    colorScheme='red'
                                    onClick={handleDeleteConfirm}
                                    ml={3}
                                >
                                    Delete
                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialogOverlay>
                </AlertDialog>
            </Flex>
            <br />
            <Modal
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                size='xl'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{`${
                        selectedData.id ? 'Edit' : 'Add New'
                    } Allergen`}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <FormLabel>Name</FormLabel>
                            <Input
                                placeholder='Enter Allergen name'
                                name='name'
                                value={formData.name}
                                onChange={handleChange}
                            />
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel>Allergen Icon</FormLabel>
                            <Select
                                style={{ width: '100%' }}
                                name='icon'
                                placeholder='Select Allergen'
                                onChange={(e) =>
                                    setFormData(() => ({
                                        ...formData,
                                        icon: e.target.value,
                                    }))
                                }
                                value={formData.icon}
                            >
                                {AvailableAllergens.map((item) => (
                                    <option value={item.value} key={item.id}>
                                        {item.label}
                                    </option>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel>Translations</FormLabel>
                            <table
                                className='table table-bordered table-hover'
                                id='tab_logic'
                            >
                                <thead>
                                    <tr>
                                        <th
                                            width='200px'
                                            className='text-center'
                                        >
                                            Select Language
                                        </th>
                                        <th
                                            width='200px'
                                            className='text-center'
                                        >
                                            Name
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {rows &&
                                        rows.map((item, index) => {
                                            return (
                                                <tr key={item.id}>
                                                    <td>
                                                        <Select
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            name='hotel_language_id'
                                                            placeholder='Select Language'
                                                            disabled={
                                                                item?.language_code
                                                            }
                                                            onChange={(e) =>
                                                                handleLanguageChange(
                                                                    e,
                                                                    index
                                                                )
                                                            }
                                                            value={
                                                                +item.hotel_language_id ||
                                                                ''
                                                            }
                                                        >
                                                            {languages.map(
                                                                (lang) => (
                                                                    <option
                                                                        key={
                                                                            lang.id
                                                                        }
                                                                        value={
                                                                            +lang.id ||
                                                                            ''
                                                                        }
                                                                        disabled={
                                                                            item?.id
                                                                        }
                                                                    >
                                                                        {
                                                                            lang.lang_name
                                                                        }
                                                                    </option>
                                                                )
                                                            )}
                                                        </Select>
                                                    </td>
                                                    <td>
                                                        <Input
                                                            type='text'
                                                            placeholder='Enter allergen name'
                                                            value={item?.name}
                                                            name='name'
                                                            onChange={(e) =>
                                                                handleLanguageChange(
                                                                    e,
                                                                    index
                                                                )
                                                            }
                                                        />
                                                    </td>

                                                    <td className='text-center'>
                                                        <IconButton
                                                            icon={
                                                                <DeleteIcon />
                                                            }
                                                            colorScheme='red'
                                                            aria-label='Remove'
                                                            onClick={() =>
                                                                handleDeleteLanguage(
                                                                    index
                                                                )
                                                            }
                                                        ></IconButton>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>

                            <Button
                                mr={3}
                                colorScheme='blue'
                                onClick={handleAddLanguage}
                                width='100px'
                                disabled={languages.length <= 0}
                            >
                                Add
                            </Button>
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel>Enabled/Disabled</FormLabel>
                            <Switch
                                defaultChecked
                                isChecked={formData.enabled}
                                onChange={(e) =>
                                    setFormData(() => ({
                                        ...formData,
                                        enabled: e.target.checked,
                                    }))
                                }
                            />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            colorScheme='blue'
                            mr={3}
                            onClick={addClickHandler}
                        >
                            Submit
                        </Button>
                        <Button onClick={() => setShowModal(false)}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    );
};

export default Allergens;
