import React, { useState } from "react";
import { convertJSONtoFormData } from "../../helper/_commonHelper";
import axios from "axios";
import Modal from 'react-modal';
import { useToast } from "@chakra-ui/toast";
import AddEditTranslations from "../translations/AddEditTranslations";
import CreateDescription from "./masterTextEditor";

const AddEditSubCategory = ({ title, data, ids, setShow, refresh, showDescriptionField }) => {
  const [formData, setFormData] = useState({
    menu_category_id: ids?.categoryId,
    name: data?.name || "",
    description: data?.description || "",
    image: undefined,
    priority: data?.priority || 0,
    tags: [],
    translations: data?.translations || [],
  });
  const [errors, setError] = useState(null);
  const toast = useToast();
  const [rows, setRows] = useState([]);


  const handleChange = (evt) => {
    let value = null;
    if (evt.target.type === "checkbox") {
      value = evt.target.checked;
    } else if (evt.target.type === "file") {
      value = evt.target.files[0];
    } else {
      value = evt.target.value;
    }
    setFormData({
      ...formData,
      [evt.target.name]: value,
    });
  };

  const saveForm = async () => {
    if (data?.id) {
      delete formData.menu_id;
    }

    try {
      let updatedRows = rows.map((row) => ({
        hotel_language_id: row.hotel_language_id,
        name: row.name,
        description: row.description,
      }));
      formData['translations'] = updatedRows;
      const datatoSend = convertJSONtoFormData(formData);
      let response = await axios.post(
        (data?.id
          ? "/outlet/update/menu/category/sub-category"
          : "/outlet/menu/sub-category") + (data?.id ? "/" + data?.id : ""),
        datatoSend
      );

      if (response.status === 201) {
        const result = response.data || {};
        showToast("Sub Category Created!", result?.message);
        setShow(false);
        refresh();
      } else if (response.status === 202) {
        const result = response.data || {};
        showToast("Sub Category Updated!", result?.message);
        setShow(false);
        refresh();
      } else if (response.status === 400) {
        const result = response.data || {};
        const keys = Object.keys(result?.message);
        let errs = keys?.map((key) => {
          return {
            field: key,
            error: result?.message?.[key]?.[0],
          };
        });
        setError(errs);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const submit = async () => {
    await saveForm();
  };
  const showToast = (title, description) => {
    toast({
      title: title || "Menu Updated!",
      description:
        `${description}!` || "Menu change has been done successfully!!",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };
  return (
    <Modal
      isOpen={open}
      onRequestClose={() => setShow(false)}
      className={'modal-dialog modal-lg modal-order-details'}
      overlayClassName={'modal-backdrop overflow-auto '}
      contentLabel='Example Modal'
    >
      {/* <div
        id="myModal"
        className="modal d-block bd-example-modal-lg"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      > */}
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h3 id="myModalLabel">
                {data?.id ? "Update " + title : "Add " + title}
              </h3>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
                onClick={() => setShow(false)}
              >
                ×
              </button>
            </div>
            <div className="modal-body">
              {/* FORM */}
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group" id="desc">
                <label htmlFor="exampleInputEmail1">Description</label>
                <CreateDescription description={formData.description} onChange={handleChange} />
              </div>
              {/* <div className="form-group">
              <label htmlFor="exampleInputEmail1">type</label>
              <input
                type="text"
                className="form-control"
                placeholder="type"
                name="type"
                value={formData.type}
                onChange={handleChange}
              />
            </div> */}
              {/* {
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Image</label>
                <input
                  type="file"
                  className="form-control"
                  placeholder="image"
                  name="image"
                  // value={formData.image}
                  onChange={handleChange}
                />
              </div>
            } */}
              {/* <div className="form-group">
              <label htmlFor="exampleInputEmail1">disclaimer</label>
              <input
                type="text"
                className="form-control"
                placeholder="disclaimer"
                name="disclaimer"
                value={formData.disclaimer}
                onChange={handleChange}
              />
            </div> */}
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Priority</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Priority"
                  name="priority"
                  value={formData.priority}
                  onChange={handleChange}
                />
              </div>
              {/* <div className="form-group">
              <label htmlFor="exampleInputEmail1">start_time</label>
              <input
                type="time"
                className="form-control"
                placeholder="start_time"
                name="start_time"
                value={formData.start_time}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">end_time</label>
              <input
                type="time"
                className="form-control"
                placeholder="end_time"
                name="end_time"
                value={formData.end_time}
                onChange={handleChange}
              />
            </div> */}

              <div className='form-group'>
                <label htmlFor='xampleInputEmail1'>Translations</label>
                <AddEditTranslations
                  translations={formData.translations}
                  rows={rows}
                  setRows={setRows}
                  showDescriptionField={showDescriptionField}
                />
              </div>

              <div className="text-danger">
                {errors &&
                  errors.map((err) => (
                    <>
                      <div>
                        {err?.field?.toUpperCase()} : {err?.error}
                      </div>
                    </>
                  ))}
              </div>
            </div>

            <div className="modal-footer">
              <button className="btn" onClick={() => setShow(false)}>
                Close
              </button>
              <button className="btn btn-primary" onClick={() => submit()}>
                Save changes
              </button>
            </div>
          </div>
        </div>
      {/* </div> */}
    </Modal>
  );
};

export default AddEditSubCategory;
