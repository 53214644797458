import * as firebase from "firebase/app";
import "firebase/messaging";

const initializedFirebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyD2T_UNmkNv4zgKqy8am3LCodS2AL-JTCg",
  authDomain: "delivery-takeaway.firebaseapp.com",
  databaseURL: "https://delivery-takeaway.firebaseio.com",
  projectId: "delivery-takeaway",
  storageBucket: "delivery-takeaway.appspot.com",
  messagingSenderId: "190939041685",
  appId: "1:190939041685:web:79c1d0b1f348c48266b09c",
  measurementId: "G-57T107V3Z6",
});
const messaging = initializedFirebaseApp.messaging();
messaging.usePublicVapidKey(
  // Project Settings => Cloud Messaging => Web Push certificates
  "BNwOUiQjO_psyG4bajlwkVnJqjI5OyjmVGSdkYefqi4ISrsJPkS_LNBzUjY9eJpiU4eii5eiWP2OLv3nRe8bE0o"
);

export { messaging };

// import firebase from "firebase/app";
// import "firebase/messaging";

// const firebaseConfig = {
//   apiKey: "AIzaSyD2T_UNmkNv4zgKqy8am3LCodS2AL-JTCg",
//   authDomain: "delivery-takeaway.firebaseapp.com",
//   databaseURL: "https://delivery-takeaway.firebaseio.com",
//   projectId: "delivery-takeaway",
//   storageBucket: "delivery-takeaway.appspot.com",
//   messagingSenderId: "190939041685",
//   appId: "1:190939041685:web:79c1d0b1f348c48266b09c",
//   measurementId: "G-57T107V3Z6",
// };

// firebase.initializeApp(firebaseConfig);

// const messaging = firebase.messaging.isSupported() ? firebase.messaging() : {};

// const publicKey =
//   "BNwOUiQjO_psyG4bajlwkVnJqjI5OyjmVGSdkYefqi4ISrsJPkS_LNBzUjY9eJpiU4eii5eiWP2OLv3nRe8bE0o";

// export const getToken = async (setTokenFound) => {
//   let currentToken = "";
//   try {
//     currentToken = await messaging.getToken({ vapidKey: publicKey });
//     if (currentToken) {
//       setTokenFound(true);
//     } else {
//       setTokenFound(false);
//     }
//   } catch (error) {
//     console.log("An error occurred while retrieving token.", error);
//   }
//   return currentToken;
// };

// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     messaging.onMessage((payload) => {
//       resolve(payload);
//     });
//   });
