import React from "react";
import Modal from "react-modal";

function AssignPickup(props) {
  const {
    open,
    close,
    name,
    nameHandle,
    phoneNumber,
    phoneNumberHandle,
    confirmHandle,
    error
  } = props;
  return (
    <Modal
      isOpen={open}
      onRequestClose={close}
      className={"modal-dialog modal-sm modal-asignee"}
      overlayClassName={"modal-backdrop overflow-auto "}
      contentLabel="Example Modal"
    >
      <div className="modal-content" aria-modal="true" id="modal-content-block">
        <div className="modal-header">
          <div className="title">Picked Up By</div>
          <button className="btn btn-icon" onClick={close}>
            <i className="fa fa-times"></i>
          </button>
        </div>

        <div className="modal-body" id="print-details">
          <div className="form-group">
            <div className="row">
              <div className="col-md-4">
                <label>Name : &nbsp;</label>
              </div>
              <div className="col-md-8">
              <input type="text" value={name} onChange={nameHandle} className="pickUpContact"/>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-md-4">
                <label>Phone Number : &nbsp;</label>
              </div>
              <div className="col-md-8">
                <input
                  type="text"
                  value={phoneNumber}
                  onChange={phoneNumberHandle}
                  className="pickUpContact"
                />
              </div>
              {error && <div style={{ color: 'red', fontSize: '12px', display: 'flex', width: '100%', justifyContent: 'center', marginTop: '1rem' }}>Mobile Number must be between 8 and 15 digits</div>}
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            disabled={!(phoneNumber && name)}
            className="btn"
            onClick={confirmHandle}
          >
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default AssignPickup;
