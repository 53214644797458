import React, { useEffect, useState } from 'react';
import OrderCard from '../common/OrderCard';
import OrderDetailsPopup from '../common/OrderDetailsPopup';
import AssignModal from '../common/AssignModal';
import Loading from '../common/Loading';
import TransitionLoader from '../common/TransitionLoader';
import axios from 'axios';
import {
    fetchOrderList,
    updateActionTakenOnOrderID,
} from '../../store/slices/ird/irdSlice';
import { useDispatch } from 'react-redux';

const PunchedIrdOrder = (props) => {
    const [showLoader, setShowLoader] = useState(false);
    const [showAssignModel, setShowAssignModel] = useState(false);
    const [selectedAssosiate, setSelectedAssosiate] = useState('');
    const [deliver_by, setDeliver_by] = useState('vserve');
    const [selectedOrderId, setSelectedOrderId] = useState();
    const [showOrderDetailModal, setShowOrderDetailModal] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState({});
    const [assignLoading, setAssignLoading] = useState(false);
    const [assignError, setAssignError] = useState('');
    const { data, loading } = props;
    const dispatch = useDispatch();

    const handleClosePopup = () => {
        setSelectedOrder({});
        setShowOrderDetailModal(false);
    };
    const handleCloseAssignPopup = () => {
        setShowAssignModel(false);
        setSelectedAssosiate('');
    };
    const openOrderPopup = (item) => {
        setSelectedOrder(item);
        setShowOrderDetailModal(true);
    };

    useEffect(() => {
        getOrders();
    }, [showAssignModel]);

    useEffect(() => {
        let myInterval = setInterval(getOrders, 300000);
        return () => {
            clearInterval(myInterval);
        };
    }, []);

    const getOrders = () => {
        dispatch(fetchOrderList());
    };

    const saveAssociate = async () => {
        setAssignLoading(true);
        setAssignError('');

        try {
            let response = await axios.post(`/outlet/order/assign/associate`, {
                order_id: selectedOrderId.toString(),
                associate_id: deliver_by === 'dunzo' ? '' : selectedAssosiate,
                deliver_by: deliver_by,
            });
            if (response.status === 201) {
                setShowAssignModel(false);
                setSelectedAssosiate('');
                dispatch(fetchOrderList());
            }
        } catch (error) {
            const { response } = error;
            const { request, ...errorObject } = response;
            console.log(request);
            if (errorObject.status === 400) {
                const message =
                    errorObject?.data?.message ||
                    'Something went wrong!! Please try again.';
                setAssignError(message);
            }
        } finally {
            setAssignLoading(false);
        }
    };
    const cancelDunzoRequest = async (id) => {
        try {
            let response = await axios.post(
                `outlet/order_dunzo_delivery/${id}`,
                {
                    cancellation_reason: '',
                }
            );
            if (response.status === 201) {
                dispatch(fetchOrderList());
            }
        } catch (error) {
            const { response } = error;
            const { request, ...errorObject } = response;
            console.log(request);
            if (errorObject.status === 400) {
                window.alert(JSON.parse(request.responseText)?.message);
            }
        }
    };

    const handleClearSave = async (item) => {
        dispatch(updateActionTakenOnOrderID(item.order_detail.order_id));
        try {
            setShowLoader(true);
            let response = await axios.put(
                `/outlet/order/dispatch/` + item.order_detail.order_id
            );
            if (response.status === 202) {
                setShowLoader(false);
                dispatch(fetchOrderList());
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            setShowLoader(false);
        }
    };

    const openAssociate = (item) => {
        dispatch(updateActionTakenOnOrderID(item.order_detail.order_id));
        setShowAssignModel(true);
        setAssignError('');
        setSelectedOrderId(item.id);
        if (item?.on_trip_associate?.associate_id) {
            setSelectedAssosiate(item?.on_trip_associate?.associate_id);
        }
    };
    return (
        <section className='tab-wrapper'>
            {loading ? (
                <Loading />
            ) : data.length === 0 ? (
                <div className='container'>
                    <div
                        className=''
                        style={{
                            margin: '25vh auto',
                            textAlign: 'center',
                            color: '#8e8a8a',
                        }}
                    >
                        <p>No Orders found</p>
                    </div>
                </div>
            ) : (
                <div className='container-fluid'>
                    <div className='row home-screen'>
                        {data.length > 0 &&
                            data.map((item) => {
                                return (
                                    <OrderCard
                                        tabType={'InKitchen'}
                                        key={item.id}
                                        item={item}
                                        viewDetails={() => openOrderPopup(item)}
                                        footerBtn={() => handleClearSave(item)}
                                        cancelDunzoRequest={() =>
                                            cancelDunzoRequest(item.id)
                                        }
                                        btnTitle={
                                            item?.order_detail?.service_type ===
                                            'takeaway'
                                                ? 'Ready'
                                                : 'Dispatch'
                                        }
                                        timerTime={
                                            item?.order_detail?.punchedin_at
                                        }
                                        associateBtn={() => {
                                            openAssociate(item);
                                        }}
                                    />
                                );
                            })}
                    </div>
                </div>
            )}
            {showOrderDetailModal && (
                <OrderDetailsPopup
                    item={selectedOrder}
                    open={showOrderDetailModal}
                    close={handleClosePopup}
                />
            )}
            {showAssignModel && (
                <AssignModal
                    close={handleCloseAssignPopup}
                    open={showAssignModel}
                    onAssosiateSelection={(e) =>
                        setSelectedAssosiate(e.target.value)
                    }
                    selectedAssosiate={selectedAssosiate}
                    saveAssociate={saveAssociate}
                    deliver_by={deliver_by}
                    setDeliver_by={(e) => setDeliver_by(e.target.value)}
                    assignLoading={assignLoading}
                    assignError={assignError}
                />
            )}
            {showLoader && (
                <TransitionLoader
                    previousStage={'InKitchen'}
                    newStage={'Dispatched/Ready'}
                />
            )}
        </section>
    );
};

export default PunchedIrdOrder;
