import React, { useState, useEffect } from "react";
import axios from "axios";
import Time from "../Times/timesOne";
import Loading from "../common/Loading";
import OrderDetailsPopup from "../common/OrderDetailsPopup";
import ReactPaginate from "react-paginate";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import AddSocialOrder from "../common/AddSocialOrder";
import { useToast } from "@chakra-ui/toast";
import { getItemDetail } from "../../helper/_commonHelper";
import moment from "moment";

function getNewDate() {
  let tDate = moment().format('DD/MM/YYYY').split("/");
  let newDateToday = `${tDate[2]}-${tDate[1]}-${tDate[0]}`;
  return newDateToday;
}
const OrderHistory = () => {
  let formatedDate = getNewDate();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [pageNumber, setpageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(10);
  const [maxPageLimit, setMaxPageLimit] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [previewSelectedItem, setPreviewSelectedItem] = useState(null);
  const [isSocialOrder, setIsSocialOrder] = useState(false);
  const [filterParameters, setFilterParameters] = useState({
    date_start: formatedDate,
    date_end: formatedDate,
    search: "",
    source_type: "all",
    source_name: "",
  });
  useEffect(() => {
    fetchOrderHistory();
  }, [pageNumber,pageCount, isSocialOrder, filterParameters]);
  const fetchOrderHistory = async () => {
    setDataList([]);

    try {
      setLoading(true);
      let filterParams = "";
      Object.keys(filterParameters).forEach((item) => {
        let key = item,
          value = filterParameters[item];
        if (value) {
          filterParams += `&${key}=${value}`;
        }
      });
      let response = await axios.get(
        `/outlet/order/history?page=${pageNumber}&per_page=${pageCount}${filterParams}`
      );
      if (response.status === 200) {
        const data = response.data.data || {};
        setMaxPageLimit(data.last_page);
        setTotalOrders(data.total);
        if (data?.data?.length) {
          let finalData = data?.data?.sort((a, b) => b.id - a.id);
          finalData = finalData?.map((x) => {
            return {
              ...x,
              calculatedData: getItemDetail(x),
            };
          });
          setDataList(finalData);
        }
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const renderOrderItems = (item) => {
    return item.order_menu_items.map((item) => {
      return (
        <table className="inner_table" key={item.id}>
          <tbody>
            <tr>
              <td className="booking-header ">
                {item?.item?.name} - {item.quantity}
              </td>
            </tr>
            {item.order_addons.length ? (
              <tr>
                <td style={{ padding: "0px" }}>
                  <ol>
                    {item.order_addons.map((addon) => (
                      <li key={addon.id}>{addon.item?.name} </li>
                    ))}
                  </ol>
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      );
    });
  };

  const handleItemPopup = (data) => {
    setIsModalOpen(true);
    setPreviewSelectedItem(data);
  };
  const handlerFilterParameter = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFilterParameters({ ...filterParameters, [name]: value });
  };
  const applyFilter = () => {
    fetchOrderHistory();
  };
  const clearFilter = () => {
  let formatedDate = getNewDate();

    setFilterParameters({
        ...filterParameters,
      date_start: formatedDate,
      date_end: formatedDate,
      search: "",
      source_type: "all",
      source_name: "",
    });
  };

  const deleteOrder = async (e, itemId) => {
    e.stopPropagation();

    if (window.confirm("D you want to delete this order?")) {
      try {
        let response = await axios.delete(`/outlet/order/${itemId}`);

        if (response.status === 200) {
          toast({
            title: "Order Delete Successfully",
            description: response.message,
            status: "success",
            duration: 2000,
            isClosable: true,
          });
          fetchOrderHistory();
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };
  return (
    <main className="order-history-wrapper revenue_wrapper">
      <div className="aside-left">
        <div className="revenue_title">Order history filters</div>
        <div className="revenue_main">
          <div className="dates_wrapper">
            {/* <div className="form_group">
              <label htmlFor="start_date">Order Source:</label>
              <select
                id="source_type"
                name="source_type"
                className="form-control"
                onChange={handlerFilterParameter}
                value={filterParameters.source_type}
              >
                <option value="all">All</option>
                <option value="onsite">Vserve</option>
                <option value="offsite">Other Platform</option>
              </select>
            </div> */}
            {/* {filterParameters.source_type === "offsite" ? (
              <div className="form_group">
                <label htmlFor="source_name">Order Source:</label>
                <select
                  id="source_name"
                  name="source_name"
                  className="form-control"
                  onChange={handlerFilterParameter}
                  value={filterParameters.source_name}
                >
                  <option className="option" value="" disabled>
                    --Select Option --
                  </option>
                  <option className="option" value="facebook">
                    Facebook
                  </option>
                  <option className="option" value="instagram">
                    Instagram
                  </option>
                  <option className="option" value="linkedin">
                    Linkedin
                  </option>
                  <option className="option" value="twitter">
                    Twitter
                  </option>
                  <option className="option" value="zomato">
                    Zomato
                  </option>
                  <option className="option" value="swiggy">
                    Swiggy
                  </option>
                  <option className="option" value="other">
                    Other
                  </option>
                </select>
              </div>
            ) : (
              ""
            )} */}
            <div className="form_group">
              <label htmlFor="date_start">Start Date:</label>
              <input
                type="date"
                id="date_start"
                name="date_start"
                value={filterParameters.date_start}
                onChange={handlerFilterParameter}
              />
            </div>
            <div className="form_group">
              <label htmlFor="date_end">End Date:</label>
              <input
                type="date"
                id="date_end"
                name="date_end"
                value={filterParameters.date_end}
                onChange={handlerFilterParameter}
              />
            </div>
            <div className="form_group">
              <label htmlFor="search">
                Seach by Name/Mobile No./Order Id
              </label>
              <input
                type="text"
                id="search"
                name="search"
                placeholder="Seach by Name/Mobile No./Order Id"
                value={filterParameters.search}
                onChange={handlerFilterParameter}
              />
            </div>
            <div className="form_group">
              <div style={{ display: "flex" }}>
                <button
                  className="btn btn-submit"
                  onClick={applyFilter}
                  style={{ flex: "1" }}
                >
                  Apply Filters
                </button>
                <button
                  className="btn btn-submit"
                  onClick={clearFilter}
                  style={{ flex: "1" }}
                >
                  Clear filters
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="aside-right">
        <div className="container-fluid">
          <div className="filter-container">
            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="btn download-excel"
              table="table-ird"
              filename="tablexls"
              sheet="tablexls"
              buttonText="Download as XLS"
            />
            {/* <button
              className="btn btn-primary"
              onClick={() => setIsSocialOrder(true)}
            >
              Add Manual Order
            </button> */}
          </div>

          <>
            <div
              className="row home-order-screen"
              style={{ position: "relative" }}
            >
              {loading ? (
                <Loading />
              ) : dataList.length === 0 ? (
                <div className="col-md-12">
                  <div
                    className=""
                    style={{
                      margin: "25vh auto",
                      textAlign: "center",
                      fontSize: "20px",
                      color: "#8e8a8a",
                    }}
                  >
                    <p>No Order Found..</p>
                  </div>
                </div>
              ) : dataList.length > 0 ? (
                <table
                  className="table table-striped table-hover table-history"
                  id="table-ird"
                >
                  <thead>
                    <tr>
                      <th className="text-center">Order ID</th>
                      <th className="text-center">Order Source</th>
                      <th className="text-center">Order Items</th>
                      <th className="text-center">Order Received Time</th>
                      <th className="text-center">Order Accepted Time</th>
                      <th className="text-center">Order Punched Time</th>
                      <th className="text-center">
                        Order delivered/Pickup Time
                      </th>
                      <th className="text-center">Payment Status</th>
                      <th className="text-center">Payment Type</th>
                      <th className="text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataList.map((item) => {
                      return (
                        <tr
                          key={item.id}
                          className="cursor-point"
                          onClick={() => handleItemPopup(item)}
                        >
                          <td className={"text-center"}>#{item?.id}</td>
                          <td className={"text-center"}>
                            <span
                              className={`type-tile ${item?.order_detail?.source_type}`}
                            >
                              {item?.order_detail?.source_type === "onsite"
                                ? "Vserve"
                                : item?.order_detail?.source_type === "offsite"
                                ? "Other Platform"
                                : item?.order_detail?.source_type}
                            </span>
                            {item?.order_detail?.source_name ? (
                              <span
                                className={`type-tile ${item?.order_detail?.source_name}`}
                              >
                                {item?.order_detail?.source_name?.toUpperCase()}
                              </span>
                            ) : (
                              ""
                            )}
                          </td>
                          <td className="text-center ">
                            {item?.order_detail?.check_no ? (
                              <>
                                <label className="details-label">
                                  CHECK NUMBER: {"  "}
                                  <b>{item?.order_detail?.check_no || "--"}</b>
                                </label>
                              </>
                            ) : null}
                            {renderOrderItems(item)}
                          </td>
                          <td className="text-center">
                            <Time
                              newDate={item.order_detail.created_at}
                              timesString={item.order_detail.created_at}
                            />
                          </td>
                          <td className="text-center">
                            <Time
                              newDate={item.order_detail.accepted_at}
                              timesString={item.order_detail.accepted_at}
                            />
                          </td>
                          <td className="text-center">
                            <Time
                              newDate={item.order_detail.punchedin_at}
                              timesString={item.order_detail.punchedin_at}
                            />
                          </td>
                          <td className="text-center">
                            <Time
                              newDate={item.order_detail.cleared_at}
                              timesString={item.order_detail.cleared_at}
                            />
                          </td>
                          <td className="text-center">
                            {item.payment_status  === 'offline' ? 'Pay on Delivery' :'Paid Online'}
                          </td>
                          <td className="text-center payment-type">
                            {item.payment_type===null?'-':item.payment_type==='EDC'?'CARD':'CASH'}
                          </td>
                          <td className="text-center">
                            {item?.order_detail?.source_type === "offsite" ? (
                              <button
                                className="btn btn-icon"
                                style={{
                                  backgroundColor: "red",
                                  color: "#fff",
                                }}
                                onClick={(e) => deleteOrder(e, item.id)}
                              >
                                <i className="fa fa-trash"></i>
                              </button>
                            ) : null}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                ""
              )}
            </div>
            <div className="pagination-container">
              <div className="totalcount">
                <label>Total Orders: </label>{" "}
                <label>
                  <b>{totalOrders}</b>
                </label>
              </div>
              <div className="form-group1">
              <label>Row per Page: </label>
              <select
                name="count"
                id="count"
                value={pageCount}
                onChange={(e) => setPageCount(e.target.value)}
              >
                <option defaultValue="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="75">75</option>
                <option value="100">100</option>
                <option value="150">150</option>
                <option value="200">200</option>
                <option value="250">250</option>
              </select>
            </div>
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={maxPageLimit}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={(e) => {
                  setpageNumber(e.selected + 1);
                }}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          </>
        </div>
      </div>
      {isModalOpen && (
        <OrderDetailsPopup
          item={previewSelectedItem}
          open={isModalOpen}
          close={() => setIsModalOpen(false)}
        />
      )}
      {isSocialOrder && (
        <AddSocialOrder
          open={isSocialOrder}
          close={() => setIsSocialOrder(false)}
        />
      )}
    </main>
  );
};

export default OrderHistory;
