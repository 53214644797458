import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { commonSelector } from "../../store/slices/common/commonSlice";
import { deleteCookie } from "../../helper/_cookieHelper";

function Header(props) {
  const [loading, setLoading] = useState(false);
  const { userProfile } = useSelector(commonSelector);
  const { notificationData, updateOrderByType } = props;
  let navigate = useNavigate();

  const logout = () => {
    removeToken();
    debugger;
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (let registration of registrations) {
        registration.unregister();
      }
    });
  };

  async function removeToken() {
    try {
      setLoading(true);
      let response = await axios.get(`/logout`);
      if (response.status === 200) {
        deleteCookie("token");
        navigate("/login", { replace: true });
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  }

  const getBadge = () => {
    return <span className="dot-badge"></span>;
  };
  return (
    <div className="header sticky-top">
      <div className="top-nav">
        <div className="hotelName">{userProfile.name || ""}</div>
        {notificationData["outlet"] && (
          <button
            className="btn btn-success"
            onClick={() => updateOrderByType("outlet")}
          >
            Click here to fetch new orders
          </button>
        )}
        <ul className="menuLinks">
          <li>
            <NavLink
              to="/"
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              Dashboard
              {notificationData["outlet"] && getBadge()}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="menu"
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              Menu
            </NavLink>
          </li>
          {userProfile.is_menu_access_permission && (
            <li>
              <NavLink
                to="edit-menu"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                Menu Management
              </NavLink>
            </li>
          )}
          <li>
            <NavLink
              to="order-history"
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              Orders History
            </NavLink>
          </li>
          <li>
            <div className=" logout-btn">
              {loading ? (
                <i className="fa fa-spinner fa-spin"></i>
              ) : (
                <i className="fa fa-power-off" onClick={() => logout()}></i>
              )}
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Header;
