import React from "react";

const Counter = (props) => {
  const { increaseCount, decreaseCount, count } = props;

  return (
    <div className="item-actions">
      <button className="btn btn-update-item">
        <span onClick={decreaseCount}>
          <i className="fa fa-minus"></i>
        </span>
        <span className="item-count">{count}</span>
        <span onClick={increaseCount}>
          <i className="fa fa-plus"></i>
        </span>
      </button>
    </div>
  );
};

export default Counter;
