import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { setCookie } from "../helper/_cookieHelper";

const AuthCallback = () => {
  const location = useLocation();
  let navigate = useNavigate();

  useEffect(() => {
    checkStatus();
  }, []);

  const checkStatus = () => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");
    const status = queryParams.get("status");
    if (status === "success") {
      setCookie("token", `${token}`, 1);
      navigate("/", { replace: true });
    } else {
      navigate(`/login`, { replace: true, msg: "failure" });
    }
  };

  return <div>loading...</div>;
};

export default AuthCallback;
