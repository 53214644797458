import React, { Component } from "react";

export default class times extends Component {
  render() {
    var otherTime = new Date(this.props.newDate);
    var newDate = new Date(otherTime).toDateString();

    var now = new Date(otherTime);
    var hours = now.getHours();
    var minutes = now.getMinutes();
    var ap = "AM";
    if (hours > 11) {
      ap = "PM";
    }
    if (hours > 12) {
      hours = hours - 12;
    }
    if (hours == 0) {
      hours = 12;
    }
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    var timeString = hours + ":" + minutes + ":" + ap;

    // function getClockTime(){
    //     var now    = new Date(nowIsoDateTime);
    //     var hour   = now.getHours();
    //     var minute = now.getMinutes();
    //     var second = now.getSeconds();
    //     var ap = "AM";
    //     if (hour   > 11) { ap = "PM";             }
    //     if (hour   > 12) { hour = hour - 12;      }
    //     if (hour   == 0) { hour = 12;             }
    //     if (hour   < 10) { hour   = "0" + hour;   }
    //     if (minute < 10) { minute = "0" + minute; }
    //     if (second < 10) { second = "0" + second; }
    //     var timeString = hour + ':' + minute + ':' + second + " " + ap;
    //     return timeString;
    //  }

    return (
      <>
        {newDate} {timeString}
      </>
    );
  }
}
