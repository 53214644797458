import React, { useEffect, useRef, useState } from "react";
import AddEditMenu from "./AddEditMenu";
import AddEditCategory from "./AddEditCategory";
import AddEditSubCategory from "./AddEditSubCategory";
import AddEditItem from "./AddEditItem";
import AddEditSubAddOn from "./AddEditSubAddOn";
import AddEditAddOn from "./AddEditAddOn";
import veg from "../../assets/images/veg.png";
import nonveg from "../../assets/images/non-veg.png";
import { useToast } from "@chakra-ui/toast";
import Loading from "../common/Loading";
import axios from "axios";
import { useSelector } from "react-redux";
import { commonSelector } from "../../store/slices/common/commonSlice";
import { deleteAPI, getTaxes } from "../../services/apiService";
import { getCurrencySymbol } from "../../helper/_commonHelper";

const MenuEdit = () => {
  const [isDev] = useState(true);
  const [selectedMenuId, setSelectedMenuId] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState("");
  const [selectedItemId, setSelectedItemId] = useState("");
  const [selectedSubAddonId, setSelectedSubAddonId] = useState("");
  const [selectedAddonId, setSelectedAddonId] = useState("");
  const [menuList, setMenuList] = useState(null);
  const [categoryList, setCategoryList] = useState(null);
  const [subCategoryList, setSubCategoryList] = useState(null);
  const [itemList, setitemData] = useState(null);
  const [subAddonList, setsubAddonList] = useState(null);
  const [addonList, setAddonList] = useState(null);
  const [categoryClickedWithoutSubCategory] = useState(false);

  const [successMessage, setSuccessMessage] = useState(null);

  const [MenuModel, setMenuModel] = useState(null);
  const [CategoryModel, setCategoryModel] = useState(null);
  const [SubCategoryModel, setSubCategoryModel] = useState(null);
  const [ItemModel, setItemModel] = useState(null);
  const [SubAddOnModel, setSubAddOnModel] = useState(null);
  const [AddOnModel, setAddOnModel] = useState(null);

  const [showMenuModel, setshowMenuModel] = useState(false);
  const [showCategoryModel, setshowCategoryModel] = useState(false);
  const [showSubCategoryModel, setshowSubCategoryModel] = useState(false);
  const [showItemModel, setshowItemModel] = useState(false);
  const [showSubAddOnModel, setshowSubAddOnModel] = useState(false);
  const [showAddOnModel, setshowAddOnModel] = useState(false);
  const [taxes, setTaxes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [ids, setIds] = useState({
    menuId: null,
    categoryId: null,
    subCategoryId: null,
    itemId: null,
    subAddOnId: null,
    addOnId: null,
  });

  // useref to keep item position
  const itemRef = useRef(null);
  const toast = useToast();

  const { userProfile } = useSelector(commonSelector);
  // loading for all api call

  useEffect(() => {
    getCategoryList(selectedMenuId);
  }, [selectedMenuId]);

  useEffect(() => {
    getItemsList(selectedCategoryId, selectedSubCategoryId);
  }, [selectedCategoryId, selectedSubCategoryId]);

  const setSuccess = (msg) => {
    setSuccessMessage(msg);
    setTimeout(() => {
      setSuccessMessage("");
    }, 3000);
  };

  const openMenu = (menu, isEdit = true) => {
    setIds({
      menuId: selectedMenuId,
      categoryId: null,
      subCategoryId: null,
      itemId: null,
      subAddOnId: null,
      addOnId: null,
    });
    if (isEdit) {
      setMenuModel(menu);
    } else {
      setMenuModel(null);
    }
    setshowMenuModel(true);
  };
  const openCategory = (category, isEdit = true) => {
    setIds({
      menuId: selectedMenuId,
      categoryId: category?.id,
      subCategoryId: null,
      itemId: null,
      subAddOnId: null,
      addOnId: null,
    });
    setMenuModel(selectedMenuId);
    if (isEdit) {
      setCategoryModel(category);
    } else {
      setCategoryModel(null);
    }
    setshowCategoryModel(true);
  };
  const openSubCategory = (subCategory, categoryId, isEdit = true) => {
    setIds({
      menuId: selectedMenuId,
      categoryId: categoryId,
      subCategoryId: subCategory?.id,
      itemId: null,
      subAddOnId: null,
      addOnId: null,
    });
    setMenuModel(selectedMenuId);
    if (isEdit) {
      setSubCategoryModel(subCategory);
    } else {
      setSubCategoryModel(null);
    }
    setshowSubCategoryModel(true);
  };
  const openItem = (item, categoryId, subCategoryId, isEdit = true) => {
    setIds({
      menuId: selectedMenuId,
      categoryId: categoryId,
      subCategoryId: subCategoryId,
      itemId: item?.id,
      subAddOnId: null,
      addOnId: null,
    });
    if (isEdit) {
      setItemModel(item);
    } else {
      setItemModel(null);
    }
    setshowItemModel(true);
  };
  const openSubAddOn = (
    subAddOn,
    categoryId,
    subCategoryId,
    itemId,
    isEdit = true
  ) => {
    setIds({
      menuId: selectedMenuId,
      categoryId: categoryId,
      subCategoryId: subCategoryId,
      itemId: itemId,
      subAddOnId: selectedSubAddonId,
      addOnId: null,
    });
    if (isEdit) {
      setSubAddOnModel(subAddOn);
    } else {
      setSubAddOnModel(null);
    }
    setshowSubAddOnModel(true);
  };

  const openAddOn = (
    addOn,
    categoryId,
    subCategoryId,
    itemId,
    subAddOnId,
    isEdit = true
  ) => {
    setIds({
      menuId: selectedMenuId,
      categoryId: categoryId,
      subCategoryId: subCategoryId,
      itemId: itemId,
      subAddOnId: subAddOnId,
      addOnId: addOn?.id,
    });
    if (isEdit) {
      setAddOnModel(addOn);
    } else {
      setAddOnModel(null);
    }
    setshowAddOnModel(true);
  };

  const getMenus = async () => {
    try {
      setIsLoading(true);
      let response = await axios.get(`/outlet/menus`);
      let taxResponse = await getTaxes();
      if (response.status === 200) {
        const data = response.data.data || [];
        setMenuList(data);
        setSelectedMenuId(data?.[0]?.id);
      }
      if (taxResponse.status === 200) {
        const data = taxResponse.data.data || [];
        setTaxes(data);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };
  const getCategoryList = async (menuID = selectedMenuId) => {
    if (menuID) {
      setIsLoading(true);
      setAddonList(null);
      setsubAddonList(null);
      setitemData(null);
      setSubCategoryList(null);
      setSelectedItemId(null);
      setSelectedMenuId(menuID);

      try {
        let response = await axios.get(`/outlet/menu/categories/${menuID}`);
        if (response.status === 200) {
          const data = response.data.data || [];
          setCategoryList(data);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsLoading(false);
      }
    }
  };
  const getSubCategoryList = async (catID = selectedCategoryId) => {
    if (catID) {
      setIsLoading(true);
      setsubAddonList(null);
      setAddonList(null);
      setitemData(null);
      setSubCategoryList(null);
      setSelectedSubCategoryId(null);
      setSelectedCategoryId(catID);
      try {
        //  to get subcategories
        let categoryResponse = await axios.get(
          `/outlet/menu/category/sub-categories/${catID}`
        );
        // to get item without subcategory
        let itemResponse = await axios.get(`/outlet/menu/items/${catID}`);
        if (categoryResponse.status === 200 && itemResponse.status === 200) {
          const categoryData = categoryResponse.data.data || [];
          const itemData = itemResponse.data.data || [];
          setSubCategoryList(categoryData);
          setitemData(itemData);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsLoading(false);
      }
    }
  };
  const getItemsList = async (
    catID = selectedCategoryId,
    subCatID = selectedSubCategoryId
  ) => {
    if (catID) {
      setsubAddonList(null);
      setAddonList(null);
      setSelectedItemId(null);

      try {
        setIsLoading(true);

        let response = await axios.get(
          subCatID
            ? `/outlet/menu/items/${catID}/${subCatID}`
            : `/outlet/menu/items/${catID}`
        );
        if (response.status === 200) {
          const data = response.data.data || [];
          setitemData(data);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsLoading(false);
      }
    }
  };
  const getSubAddonList = async (e, itemID = selectedItemId) => {
    if (itemID) {
      setsubAddonList(null);
      setAddonList(null);
      setSelectedItemId(itemID);
      setIsLoading(true);

      try {
        let response = await axios.get(`/outlet/menu/item/subaddons/${itemID}`);
        if (response.status === 200) {
          const data = response.data.data || [];
          setsubAddonList(data);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsLoading(false);
      }
    }
  };
  const getAddonList = async (subAddonID = selectedSubAddonId) => {
    if (subAddonID && selectedItemId) {
      setIsLoading(true);
      setSelectedSubAddonId(subAddonID);
      try {
        let response = await axios.get(
          `/outlet/menu/item/subaddon/addons/${selectedItemId}/${subAddonID}`
        );
        if (response.status === 200) {
          const data = response.data.data || [];
          setAddonList(data);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    getMenus();
  }, []);

  const deleteAction = async (name, id) => {
    let response = null;
    if (!window.confirm("Are you sure to delete this record?")) {
      return;
    }
    switch (name) {
      case "menu":
        response = await deleteAPI(`menu/${id}`);
        break;
      case "subC":
        response = await deleteAPI(`menu/sub-category/${id}`);
        break;
      case "cat":
        response = await deleteAPI(`menu/category/${id}`);
        break;
      case "item":
        response = await deleteAPI(`menu/item/${id}`);
        break;
      case "subA":
        response = await deleteAPI(`menu/sub-addon/${id}`);
        break;
      case "addon":
        response = await deleteAPI(`menu/addon/${id}`);
        break;
    }
    if (response.status === 200) {
      const result = response.data || {};
      showToast(`${name} Deleted!`, result?.message);
      switch (name) {
        case "menu":
          getMenus();
          break;
        case "subC":
          getCategoryList();
          break;
        case "cat":
          getSubCategoryList();
          break;
        case "item":
          getItemsList();
          break;
        case "subA":
          getSubAddonList();
          break;
        case "addon":
          getAddonList();
          break;
        default:
          getMenus();
          break;
      }
    } else if (response.status === 400) {
      const result = response.data || {};
      console.log(result);
    }
  };
  const showToast = (title, description) => {
    toast({
      title: title || "Menu Updated!",
      description:
        `${description}!` || "Menu change has been done successfully!!",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };
  const MenuComponent = () => {
    return (
      <>
        {menuList?.map((item) => (
          <div
            className={
              "tabs " +
              (item.id === selectedMenuId ? " selectMenu " : " ") +
              (item.enabled == 0 ? " disabled" : " ")
            } //selectMenu
            key={item.id}
            onClick={() => getCategoryList(item.id)}
            style={{
              backgroundImage:
                "url(https://master-vserve.s3.ap-south-1.amazonaws.com/menus/" +
                encodeURI(item?.image).replace("(", "%28").replace(")", "%29") +
                ")",
            }}
          >
            <div className="card-title category-title">{item.name}</div>
            <div className="dropdown-wrapper">
              <button className="btn-toggle1">
                <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
              </button>
              <div id="myDropdown" className="dropdown-content">
                <span
                  className="btn-b btn-b-success"
                  onClick={() => {
                    openMenu(item);
                  }}
                >
                  <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                  {isDev && "Edit Menu"}
                </span>
                <span
                  className="btn-b btn-b-danger "
                  onClick={() => {
                    deleteAction("menu", item.id);
                  }}
                >
                  <i className="fa fa-trash-o" aria-hidden="true"></i>
                  {isDev && "Delete this Menu"}
                </span>
              </div>
            </div>
          </div>
        ))}
        <div className="tabs tabs-add-new" onClick={() => openMenu(false)}>
          <i className="fa fa-plus-square" aria-hidden="true"></i>
          {isDev && "Add Menu"}
        </div>
      </>
    );
  };
  const categoryComponent = () => {
    return (
      <>
        {categoryList?.map((category, index) => (
          <div className="category-card" id="category" key={category.id}>
            <div
              className={
                "cat-card-wrapper" +
                (category.id === selectedCategoryId ? " active" : "") +
                (category.enabled == 0 ? " disabled" : " ")
              }
              style={{
                backgroundImage:
                  "url(https://master-vserve.s3.ap-south-1.amazonaws.com/menu-categories/" +
                  encodeURI(category?.image)
                    .replace("(", "%28")
                    .replace(")", "%29") +
                  ")",
              }}
              onClick={() => getSubCategoryList(category.id)}
            >
              <div className="showhim">
                <div className="dropdown-wrapper">
                  <button className="btn-toggle1">
                    <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                  </button>
                  <div id="myDropdown" className="dropdown-content">
                    <span
                      className="btn-b btn-b-success "
                      onClick={() => {
                        openCategory(category, selectedMenuId);
                      }}
                    >
                      <i
                        className="fa fa-pencil-square-o"
                        aria-hidden="true"
                      ></i>
                      {isDev && "Edit Category"}
                    </span>
                    <span
                      className="btn-b btn-b-danger "
                      onClick={() => {
                        deleteAction("cat", category?.id);
                      }}
                    >
                      <i className="fa fa-trash-o" aria-hidden="true"></i>
                      {isDev && "Delete category"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="card-body1">
                {index + 1}.{category?.name}
              </div>
            </div>
            {subCategoryList && category?.id === selectedCategoryId ? (
              <div className="subcategory-wrapper">
                {subCategoryComponent(
                  category?.sub_categories,
                  index,
                  category?.id
                )}
                <div
                  className="sub-cat-wrapper add-new-tile"
                  onClick={() => openSubCategory(null, category?.id, false)}
                  style={{
                    marginLeft: "1.8rem",
                  }}
                >
                  + Add Sub Category
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        ))}
        <div
          className="cat-card-wrapper add-new-tile"
          onClick={() => openCategory(null, false)}
        >
          + Add Category
        </div>
      </>
    );
  };
  const subCategoryComponent = () => {
    return subCategoryList?.map((sub_category, index) => {
      return (
        <div
          style={{
            backgroundColor: "#f5f5f5",
            width: "18rem",
            minHeight: "3.5rem",
            marginLeft: "auto",
          }}
          className={
            "sub-cat-wrapper " +
            (sub_category.id === selectedSubCategoryId ? " selected" : "") +
            (sub_category.enabled == 0 ? " disabled" : " ")
          }
          key={index}
          onClick={() => {
            setSelectedSubCategoryId(sub_category.id);
          }}
        >
          <div className="showhim">
            <div className="dropdown-wrapper">
              <button className="btn-toggle1">
                <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
              </button>
              <div id="myDropdown" className="dropdown-content">
                <span
                  className="btn-b btn-b-success "
                  onClick={() => {
                    openSubCategory(
                      sub_category,
                      selectedCategoryId,
                      sub_category?.id
                    );
                  }}
                >
                  <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                  {isDev && "Edit Sub Category"}
                </span>
                <span
                  className="btn-b btn-b-danger "
                  onClick={() => {
                    deleteAction("subC", sub_category?.id);
                  }}
                >
                  <i className="fa fa-trash-o" aria-hidden="true"></i>
                  {isDev && "Delete this Sub Category"}
                </span>
              </div>
            </div>
            <div className="text-view-only">
              {index + 1}.{sub_category?.name}
            </div>
          </div>
        </div>
      );
    });
  };

  const itemComponent = () => {
    const items = itemList,
      // catIndex = selectedCategoryId,
      // subIndex = selectedSubCategoryId,
      categoryId = selectedCategoryId,
      subCategoryId = selectedSubCategoryId;
    const hotelCurrencyCode = userProfile.hotel?.country?.currency_code || "";
    const currencySymbol = getCurrencySymbol(hotelCurrencyCode);
    return (
      <>
        {items &&
          items?.map((item, index) => {
            if (categoryId !== selectedCategoryId) {
              return null;
            }
            if (
              subCategoryId !== selectedSubCategoryId &&
              !categoryClickedWithoutSubCategory
            ) {
              return null;
            }
            return (
              <div
                className={
                  "items-wrapper" +
                  (item.id === selectedItemId ? " selected" : "") +
                  (item.enabled == 0 ? " disabled" : " ")
                }
                key={index}
                onClick={(e) => getSubAddonList(e, item.id)}
                ref={itemRef}
              >
                <div className="showhim">
                  <div className="dropdown-wrapper">
                    <button className="btn-toggle1">
                      <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                    </button>
                    <div id="myDropdown" className="dropdown-content">
                      <span
                        className="btn-b btn-b-primary "
                        onClick={() => {
                          openSubAddOn(
                            null,
                            categoryId,
                            subCategoryId,
                            item?.id,
                            false
                          );
                        }}
                      >
                        <i className="fa fa-plus-square" aria-hidden="true"></i>
                        {isDev && "Add customization"}
                      </span>
                      <span
                        className="btn-b btn-b-success "
                        onClick={() => {
                          openItem(item, categoryId, subCategoryId, item?.id);
                        }}
                      >
                        <i
                          className="fa fa-pencil-square-o"
                          aria-hidden="true"
                        ></i>
                        {isDev && "Edit Item"}
                      </span>
                      <span
                        className="btn-b btn-b-danger "
                        onClick={() => {
                          deleteAction("item", item?.id);
                        }}
                      >
                        <i className="fa fa-trash-o" aria-hidden="true"></i>
                        {isDev && "Delete this Item"}
                      </span>
                    </div>
                  </div>
                  <div className="card-items">
                    {item?.type && (
                      <div className="item-type-icon">
                        {item?.type === "veg" && (
                          <img src={veg} alt="item-type" />
                        )}
                        {item?.type === "non_veg" && (
                          <img src={nonveg} alt="item-type" />
                        )}
                      </div>
                    )}

                    <div className="item-name">
                      <div>{item?.name}</div>
                      {item?.description && (
                        <div
                          dangerouslySetInnerHTML={{ __html: item.description }}
                          className="item-description"
                        ></div>
                      )}
                      {item?.price ? (
                        <div className="">
                          {" "}
                          {currencySymbol} {item?.price}
                        </div>
                      ) : null}
                      <div className="item-allergens">
                        {item?.allergens?.map((item, index) => (
                          <span className="allergens-tile" key={index}>
                            {item.name}
                          </span>
                        ))}
                      </div>
                      {/* <div className="item-tags">
                        {JSON.parse(item?.tags)?.map((item, index) => (
                          <span className="tags-tile" key={index}>
                            {item}
                          </span>
                        ))}
                      </div> */}
                    </div>
                    {item?.thumbnail && (
                      <div
                        className="item-img"
                        style={{ marginRight: "1.75rem" }}
                      >
                        <img
                          src={`https://master-vserve.s3.ap-south-1.amazonaws.com/menu-items/${item.thumbnail}`}
                          alt="category"
                          style={{
                            width: "140px",
                            height: " 60px",
                            borderRadius: "8px",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    )}
                    {item?.sub_addons?.length && (
                      <div className="has-customization">Has Customization</div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        {selectedCategoryId || selectedSubCategoryId ? (
          <div
            className="items-wrapper add-new-tile"
            onClick={() => {
              openItem(null, selectedCategoryId, selectedSubCategoryId, false);
            }}
          >
            + Add Item
          </div>
        ) : (
          <div />
        )}
      </>
    );
  };

  const subAddOnComponent = () => {
    return subAddonList?.map((subAddOn, index) => (
      <div
        style={{
          backgroundColor: "#f5f5f5",
        }}
        className={
          "subaddon-card-wrapper" +
          (subAddOn.id === selectedSubAddonId ? " selected" : "") +
          (subAddOn.enabled == 0 ? " disabled" : " ")
        }
        key={index}
        onClick={() => getAddonList(subAddOn.id)}
      >
        <div className="showhim">
          <div className="dropdown-wrapper">
            <button className="btn-toggle1">
              <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
            </button>
            <div id="myDropdown" className="dropdown-content">
              <span
                className="btn-b btn-b-primary "
                onClick={() => {
                  openAddOn(
                    null,
                    selectedCategoryId,
                    selectedSubCategoryId,
                    selectedItemId,
                    subAddOn?.id,
                    false
                  );
                }}
              >
                <i className="fa fa-plus-square" aria-hidden="true"></i>
                {isDev && "Add option"}
              </span>
              <span
                className="btn-b btn-b-success "
                onClick={() => {
                  setSelectedSubAddonId(subAddOn?.id);
                  openSubAddOn(
                    subAddOn,
                    selectedCategoryId,
                    selectedSubCategoryId,
                    selectedItemId,
                    subAddOn?.id
                  );
                }}
              >
                <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                {isDev && "Edit customization"}
              </span>
              <span
                className="btn-b btn-b-danger "
                onClick={() => {
                  setSelectedSubAddonId(subAddOn?.id);
                  deleteAction("subA", subAddOn?.id);
                }}
              >
                <i className="fa fa-trash-o" aria-hidden="true"></i>
                {isDev && "Delete this Customization"}
              </span>
            </div>
          </div>
          <div className="text-view-only">
            {index + 1}.{subAddOn?.name}
            <div className="has-customization">
              {subAddOn.type == "single_select"
                ? "Single Select"
                : "Multi Select"}
            </div>
          </div>
        </div>
      </div>
    ));
  };

  const addOnComponent = () => {
    return addonList?.map((addOn, index) => (
      <div className="category-div " key={index}>
        <div
          style={{
            backgroundColor: "#f5f5f5",
            minHeight: "3.5rem",
          }}
          className={
            "addon-card-wrapper" +
            (addOn.id === selectedAddonId ? " selected" : "") +
            (addOn.enabled == 0 ? " disabled" : " ")
          }
          onClick={() => setSelectedAddonId(addOn.id)}
        >
          <div className="showhim">
            <div className="dropdown-wrapper">
              <button className="btn-toggle1">
                <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
              </button>
              <div id="myDropdown" className="dropdown-content">
                <span
                  className="btn-b btn-b-success "
                  onClick={() => {
                    openAddOn(
                      addOn,
                      selectedCategoryId,
                      selectedSubCategoryId,
                      selectedItemId,
                      selectedSubAddonId,
                      addOn?.id
                    );
                  }}
                >
                  <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                  {isDev && "Edit this option"}
                </span>
                <span
                  className="btn-b btn-b-danger "
                  onClick={() => {
                    deleteAction("addon", addOn?.id);
                  }}
                >
                  <i className="fa fa-trash-o" aria-hidden="true"></i>
                  {isDev && "Delete this option"}
                </span>
              </div>
            </div>
            <div className="text-view-only">
              {index + 1}.{addOn?.name}
            </div>
          </div>
        </div>
      </div>
    ));
  };
  return (
    <div className="menu-manage-wrapper">
      {isLoading && <Loading />}
      {successMessage && (
        <div className="row">
          <div className="alert alert-success">
            <strong>Success!</strong> {successMessage}.
          </div>
        </div>
      )}
      <div className="container-fluid">
        <div className="tabs-wrapper">{MenuComponent()}</div>
      </div>
      <div className="container-fluid">
        <div className="main-container-wrap">
          <div className="categorry-wrapper">
            <div className="section-title">Category / Sub Category</div>
            {categoryComponent()}
          </div>

          <div className="item-container">
            <div className="section-title">Items</div>
            {itemComponent()}
          </div>
          {subAddonList?.length ? (
            <div className="item-container">
              <div className="section-title">Customization</div>
              {subAddOnComponent()}
            </div>
          ) : (
            <div />
          )}
          {addonList?.length ? (
            <div className="item-container">
              <div className="section-title">Options</div>

              {addOnComponent()}
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>

      {showMenuModel && (
        <AddEditMenu
          title={"Menu"}
          open={showMenuModel}
          setShow={setshowMenuModel}
          data={MenuModel}
          refresh={getMenus}
          showDescriptionField={true}
        />
      )}

      {showCategoryModel && (
        <AddEditCategory
          title={"Category"}
          open={showCategoryModel}
          setShow={setshowCategoryModel}
          ids={ids}
          refresh={getCategoryList}
          data={CategoryModel}
          setSuccess={setSuccess}
          showDescriptionField={true}
        />
      )}

      {showSubCategoryModel && (
        <AddEditSubCategory
          title={"Sub Category"}
          open={showSubCategoryModel}
          setShow={setshowSubCategoryModel}
          ids={ids}
          refresh={getSubCategoryList}
          data={SubCategoryModel}
          setSuccess={setSuccess}
          showDescriptionField={true}
        />
      )}

      {showItemModel && (
        <AddEditItem
          title={"Item"}
          open={showItemModel}
          setShow={setshowItemModel}
          ids={ids}
          refresh={getItemsList}
          data={ItemModel}
          setSuccess={setSuccess}
          taxesList={taxes?.map((x) => {
            return { id: x?.id, name: x?.name };
          })}
          showDescriptionField={true}
        />
      )}
      {showSubAddOnModel && (
        <AddEditSubAddOn
          title={"customization"}
          open={showSubAddOnModel}
          setShow={setshowSubAddOnModel}
          ids={ids}
          refresh={getSubAddonList}
          data={SubAddOnModel}
          setSuccess={setSuccess}
          showDescriptionField={false}
        />
      )}
      {showAddOnModel && (
        <AddEditAddOn
          title={"options"}
          open={showAddOnModel}
          setShow={setshowAddOnModel}
          ids={ids}
          refresh={getAddonList}
          data={AddOnModel}
          setSuccess={setSuccess}
          taxesList={taxes?.map((x) => {
            return { id: x?.id, name: x?.name };
          })}
          showDescriptionField={false}
        />
      )}
    </div>
  );
};

export default MenuEdit;
