import React, { useState } from "react";
import OrderCard from "../common/OrderCard";
import OrderDetailsPopup from "../common/OrderDetailsPopup";
import Loading from "../common/Loading";
import TransitionLoader from "../common/TransitionLoader";
import axios from "axios";
import { fetchOrderList, updateActionTakenOnOrderID } from "../../store/slices/ird/irdSlice";
import { useDispatch } from "react-redux";

const AcceptedIrdOrder = (props) => {
  const [showLoader, setShowLoader] = useState(false);

  const [checkNo, setcheckNo] = useState("");
  const [showOrderDetailModal, setShowOrderDetailModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});

  const dispatch = useDispatch();
  const { loading, data } = props;

  const handleClosePopup = () => {
    setSelectedOrder({});
    setShowOrderDetailModal(false);
  };
  const openOrderPopup = (item) => {
    setSelectedOrder(item);
    setShowOrderDetailModal(true);
  };

  const handleClearSave = async (item) => {
    dispatch(
        updateActionTakenOnOrderID(
            item.order_detail.order_id
        )
    );
    try {
      setShowLoader(true);
      let response = await axios.put(
        `/outlet/order/punched-in/${item.order_detail.order_id}?check_no=${checkNo}`
      );
      if (response.status === 202) {
        setShowLoader(false);
        dispatch(fetchOrderList());
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setShowLoader(false);
    }
  };


  return (
    <section className="tab-wrapper">
      {loading ? (
        <Loading />
      ) : data.length === 0 ? (
        <div className="container">
          <div
            className=""
            style={{
              margin: "25vh auto",
              textAlign: "center",
              color: "#8e8a8a",
            }}
          >
            <p>No Orders found</p>
          </div>
        </div>
      ) : (
        <div className="container-fluid">
          <div className="row home-screen">
            {data.length > 0 &&
              data.map((item) => {
                return (
                  <OrderCard
                    tabType={"Accepted"}
                    key={item.id}
                    item={item}
                    viewDetails={() => openOrderPopup(item)}
                    footerBtn={() => handleClearSave(item)}
                    btnTitle={"Punched in POS"}
                    timerTime={item?.order_detail?.accepted_at}
                    checkNoHandler={(value) => setcheckNo(value)}
                  />
                );
              })}
          </div>
        </div>
      )}
      {showOrderDetailModal && (
        <OrderDetailsPopup
          item={selectedOrder}
          open={showOrderDetailModal}
          close={handleClosePopup}
        />
      )}
      {showLoader && (
        <TransitionLoader previousStage={"Accepted"} newStage={"InKitchen"} />
      )}
    </section>
  );
};

export default AcceptedIrdOrder;
