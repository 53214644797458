import React, { useState } from "react";
import OrderCard from "../common/OrderCard";
import OrderDetailsPopup from "../common/OrderDetailsPopup";
import Loading from "../common/Loading";
import AssignPickup from "../common/AssignPickup";
import TransitionLoader from "../common/TransitionLoader";
import axios from "axios";
import { fetchOrderList, updateActionTakenOnOrderID } from "../../store/slices/ird/irdSlice";
import { useDispatch } from "react-redux";

const ClearOrder = (props) => {
  const [showLoader, setShowLoader] = useState(false);
  const [itemData, setitemData] = useState(null);
  const { loading, data } = props;
  const [showAssignModel, setShowAssignModel] = useState(false);
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showOrderDetailModal, setShowOrderDetailModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [error, setError] = useState('');

  const dispatch = useDispatch();

  const handleClosePopup = () => {
    setSelectedOrder({});
    setShowOrderDetailModal(false);
  };
  const openOrderPopup = (item) => {
    setSelectedOrder(item);
    setShowOrderDetailModal(true);
  };

  const savePickup = async () => {
    try {
      setShowLoader(true);
      let response = await axios.post(`/outlet/order/taken-by`, {
        order_id: itemData?.id?.toString(),
        take_by_name: name,
        take_by_mobile: phoneNumber,
      });
      if (response.status === 201) {
        handleClearSave(itemData);
        setShowLoader(false);
        setShowAssignModel(false);
        dispatch(fetchOrderList());
      }
    } catch (error) {
      setShowLoader(false);
      if (error.response && error.response.data && error.response.data.message) {
        const takeByMobileErrors = error.response.data.message.take_by_mobile;
        // Join multiple error messages if there are more than one
        setError(takeByMobileErrors ? takeByMobileErrors.join(', ') : 'An unexpected error occurred.');
      } else {
        setError('An unexpected error occurred.');
      }
    }
    props.refresh();
    setShowAssignModel(false);
  };

  const handleClearSave = async (item) => {
    dispatch(
        updateActionTakenOnOrderID(
            item.order_detail.order_id
        )
    );
    try {
      setShowLoader(true);
      let response = await axios.put(
        `/outlet/order/clear/` + item.order_detail.order_id
      );
      if (response.status === 202) {
        setShowLoader(false);
        dispatch(fetchOrderList());
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setShowLoader(false);
    }
  };


  // const formatPickupSlot = (slot) => {
  //   if (slot) {
  //     let times = slot?.split(" ")?.[1]?.split("-");
  //     if (times.length < 2) {
  //       return " ";
  //     }
  //     const fun = (timeString) => {
  //       var H = +timeString.substr(0, 2);
  //       var h = H % 12 || 12;
  //       var ampm = H < 12 || H === 24 ? "AM" : "PM";
  //       return h + timeString.substr(2, 3) + ampm;
  //     };
  //     times[0] = fun(times[0]);
  //     times[1] = fun(times[1]);
  //     return (
  //       moment(slot.split(" ")[0]).format("MM/DD/YYYY") +
  //       " , " +
  //       times.join(" - ")
  //     );
  //   }
  //   return "";
  // };
  return (
    <section className="tab-wrapper">
      {loading ? (
        <Loading />
      ) : data.length === 0 ? (
        <div
          className=""
          style={{
            margin: "25vh auto",
            textAlign: "center",
            color: "#8e8a8a",
          }}
        >
          <p>No Orders found</p>
        </div>
      ) : (
        <div className="container-fluid">
          <div className="row home-screen">
            {data.length > 0 &&
              data.map((item) => {
                return (
                  <OrderCard
                    tabType={"ClearOrder"}
                    key={item.id}
                    item={item}
                    viewDetails={() => openOrderPopup(item)}
                    footerBtn={() => handleClearSave(item)}
                    btnTitle={
                      item?.order_detail?.service_type === "takeaway"
                        ? "Picked Up"
                        : "Delivered"
                    }
                    timerTime={item?.order_detail?.dispatched_at}
                    clearOrderBtn={() => {
                      setitemData(item);
                      setShowAssignModel(true);
                    }}
                  />
                );
              })}
          </div>
        </div>
      )}
      {showOrderDetailModal && (
        <OrderDetailsPopup
          item={selectedOrder}
          open={showOrderDetailModal}
          close={handleClosePopup}
        />
      )}
      {showAssignModel && (
        <AssignPickup
          open={showAssignModel}
          close={() => setShowAssignModel(false)}
          confirmHandle={() => savePickup(false)}
          name={name}
          nameHandle={(e) => setName(e.target.value)}
          phoneNumber={phoneNumber}
          phoneNumberHandle={(e) => setPhoneNumber(e.target.value)}
          error={error}
        />
      )}

      {showLoader && (
        <TransitionLoader
          previousStage={"Dispatched/Ready"}
          newStage={"Order History"}
        />
      )}
    </section>
  );
};

export default ClearOrder;
