import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./stylesheets/main.scss";
import "./App.css";
// import { Security } from "@okta/okta-react";
// import { OktaAuth } from "@okta/okta-auth-js";
// import { oktaConfig } from "./lib/oktaConfig";
import LoginPage from "./pages/LoginPage";
import Layout from "./pages/Layout";
import RequireAuth from "./pages/RequireAuth";
// import { getToken } from "./init-fcm";
import IrdComponent from "./pages/Ird/Ird";
import IrdOrderHistoryComponent from "./pages/Ird/OrderHistory";
import IrdMenuEditComponent from "./pages/Ird/MenuEdit";
import IrdMenuComponent from "./pages/Ird/Menu";
import AuthCallback from "./pages/AuthCallback";
import { useDispatch } from "react-redux";
// import { updateDeviceToken } from "./store/slices/common/commonSlice";
// const oktaAuth = new OktaAuth(oktaConfig);
import { messaging } from "./init-fcm";
import { updateDeviceToken } from "./store/slices/common/commonSlice";

const App = () => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [isTokenFound, setIsTokenFound] = useState(false);

  useEffect(() => {
    messaging
      .requestPermission()
      .then(async function () {
        const token = await messaging.getToken();
        console.log(token);
        dispatch(updateDeviceToken(token));
      })
      .catch(function (err) {
        const token =
          "dVIBtGdRv31NUp9bnxxFR3:APA91bGGacotdQCYm1677U-vyKMI9nM8oPC7bj5Ii70LVx6cBF24fTVfdmIsW5qeWBpmF8BXW4FMXYq8O8cXDzB0yAKfrK7GghGvR3HWB2muul8FWvRPQA-kE5Yo4D-i3U6Fm2AWPe21";
        console.log(token);
        dispatch(updateDeviceToken(token));

        console.log(err);
      });
  });
  // useEffect(() => {
  //   async function tokenFunc() {
  //     let data = await getToken(setIsTokenFound);
  //     if (data) {
  //       console.log({ isTokenFound, data });
  //       dispatch(updateDeviceToken(data));
  //     }
  //   }
  //   tokenFunc();
  // }, [setIsTokenFound]);
  // const customAuthHandler = () => {
  //   navigate("login");
  // };
  // const restoreOriginalUri = async () => {
  //   // navigate(toRelativeUrl(originalUri || "/", window.location.origin),);
  // };

  const NotFound = () => {
    return <p>Not found</p>;
  };

  return (
    // <Security
    //   oktaAuth={oktaAuth}
    //   onAuthRequired={customAuthHandler}
    //   restoreOriginalUri={restoreOriginalUri}
    // >

    // </Security>
    <Routes>
      <Route
        path="/"
        element={
          <RequireAuth>
            <Layout />
          </RequireAuth>
        }
      >
        <Route index element={<IrdComponent />} />
        <Route
          exact
          path="/order-history"
          element={<IrdOrderHistoryComponent />}
        />
        <Route exact path="/edit-menu" element={<IrdMenuEditComponent />} />
        <Route exact path="/menu" element={<IrdMenuComponent />} />
      </Route>
      <Route exact path="/login" element={<LoginPage />} />
      <Route path="/auth/callback" exact element={<AuthCallback />} />
      <Route path="*" exact={true} element={<NotFound />} />
    </Routes>
  );
};

export default App;
