import React from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { commonSelector } from "../../store/slices/common/commonSlice";
import { getCurrencySymbol } from "../../helper/_commonHelper";

// import * as htmlToImage from 'html-to-image';
Modal.setAppElement("body");

function OrderDetailsPopup({ item, open, close }) {
  console.log(item);
  const { userProfile } = useSelector(commonSelector);
  const hotelCurrencyCode = userProfile.hotel?.country?.currency_code || "";
  const currencySymbol = getCurrencySymbol(hotelCurrencyCode);
  const address = item?.order_address?.[0]?.address || "";
  const splittedAddress = address.split("|");
  return (
    <div className="orderDetails">
      <Modal
        isOpen={open}
        onRequestClose={close}
        className={"modal-dialog modal-lg modal-order-details"}
        overlayClassName={"modal-backdrop overflow-auto "}
        contentLabel="Example Modal"
      >
        <div
          className="modal-content"
          aria-modal="true"
          id="modal-content-block"
        >
          <div className="modal-header">
            <div className="title">Order Summary</div>
            <button className="btn btn-icon" onClick={close}>
              <i className="fa fa-times"></i>
            </button>
          </div>

          <div className="modal-body" id="print-details">
            <div className="details-div f-d-r">
                <div className="modal-details-sec">
                  <label className="details-label">Name:</label>
                  <div className="details-value">{item?.guest?.name !== null && item?.guest?.name !== undefined ?item.guest.name : "--"}</div>
                </div>
            
              <div className="modal-details-sec">
                <label className="details-label">Nationality:</label>
                <div className="details-value">{item?.guest?.nationality !== null && item?.guest?.nationality !== undefined ? item.guest.nationality : "--"}</div>
              </div>
              <div className="modal-details-sec f-c">
                <label className="details-label">Order ID:</label>
                <div className="details-value">#{item?.id}</div>
              </div>
              {item.payment_status == "done" ? (
                <div className="modal-details-sec f-c">
                  <label className="details-label">Paytm Order ID:</label>
                  <div className="details-value">
                    #
                    {item.order_payments.length > 0 &&
                      item.order_payments[0].payment_order_id}
                  </div>
                </div>
              ) : (
                ""
              )}
              {item?.payment_status && <div className="modal-details-sec f-c">
                <label className="details-label">
                Payment Status:
                </label>
                <div className="details-value">
                  {item?.payment_status == "done"
                    ? "PAID ONLINE"
                    : item?.payment_status === "offline"
                    ? `Pay on ${item?.order_detail?.service_type}`
                    : item?.payment_status}
                </div>
              </div>}
              {item?.payment_type && <div className="modal-details-sec f-c">
                <label className="details-label payment-type">
                Payment Type:
                </label>
                <div className="details-value payment-type">
                  {item?.payment_type==='EDC'?'CARD':'CASH'}
                </div>
              </div>}
            </div>
            <div className="details-div f-d-r">
              {item.order_detail.created_at && (
                <>
                <div className="modal-details-sec">
                  <label className="details-label">ORDER Received AT:</label>
                  <div className="details-value">
                    {new Date(item.order_detail.created_at).toDateString()}{" "}
                    {new Date(item.order_detail.created_at).toLocaleTimeString(
                      [],
                      {
                        hour: "2-digit",
                        minute: "2-digit",
                      }
                    )}
                  </div>
                </div>
                  <div className="modal-details-sec f-c">
                    <label className="details-label">Marriot Bonvoy No:</label>
                    <div className="details-value">
                      {item.membership_no || 'N.A.'}
                    </div>
                  </div>
                </>
              )}

              {item.order_detail.accepted_at && (
                <div className="modal-details-sec f-c">
                  <label className="details-label">ORDER ACCEPTED AT:</label>
                  <div className="details-value">
                    {new Date(item.order_detail.accepted_at).toDateString()}{" "}
                    {new Date(item.order_detail.accepted_at).toLocaleTimeString(
                      [],
                      {
                        hour: "2-digit",
                        minute: "2-digit",
                      }
                    )}
                  </div>
                </div>
              )}
              {item.order_detail.punchedin_at && (
                <div className="modal-details-sec f-c">
                  <label className="details-label">ORDER Punched AT:</label>
                  <div className="details-value">
                    {new Date(item.order_detail.punchedin_at).toDateString()}{" "}
                    {new Date(
                      item.order_detail.punchedin_at
                    ).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </div>
                </div>
              )}
              {item.order_detail?.dispatched_at && (
                <div className="modal-details-sec f-c">
                  <label className="details-label">
                    ORDER{" "}
                    {item?.order_detail?.service_type === "takeaway"
                      ? "READY"
                      : "DISPATCHED"}{" "}
                    AT:
                  </label>
                  <div className="details-value">
                    {new Date(item.order_detail?.dispatched_at).toDateString()}{" "}
                    {new Date(
                      item.order_detail?.dispatched_at
                    ).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </div>
                </div>
              )}
            </div>
            <div className="details-div f-d-r">
              {item?.order_address?.[0]?.address && (
                <div className="modal-details-sec ">
                  <label className="details-label">Address Details:</label>
                  <div className="details-value">
                    <ul className="addresslist-data">
                      <li>
                        Address: <b>{splittedAddress[0]}</b>
                      </li>
                      <li>
                        Building No: <b>{splittedAddress[1]}</b>
                      </li>
                      <li>
                        Street No: <b>{splittedAddress[2]}</b>
                      </li>
                      <li>
                        Zone No: <b>{splittedAddress[3]}</b>
                      </li>
                      <li>
                        Area Name: <b>{splittedAddress[4]}</b>
                      </li>
                      <li>
                        Address Name:{" "}
                        <b>{item?.order_address?.[0]?.user_address?.title}</b>
                      </li>
                      <li>
                        ContactNo: <b>{item?.order_address?.[0]?.contact_no}</b>{" "}
                      </li>
                      <li>
                        Delivery Location: <b>{splittedAddress[5]}</b>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
              {/* {item?.order_detail?.source_type && (
                <div className="modal-details-sec ">
                  <label className="details-label">Source Name:</label>
                  <div className="details-value">
                    {item?.order_detail?.source_type === "onsite"
                      ? "Vserve"
                      : item?.order_detail?.source_type === "offsite"
                      ? "Other Platform"
                      : item?.order_detail?.source_type}
                    {item?.order_detail?.source_name
                      ? ` - ${item?.order_detail?.source_name}`
                      : ""}
                  </div>
                </div>
              )} */}
              {/* {item?.order_address?.[0]?.contact_no && (
                <div className="modal-details-sec ">
                  <label className="details-label">
                    Order Receiver Contact Number:
                  </label>
                  <div className="details-value">
                    {item?.order_address?.[0]?.contact_no || ""}
                  </div>
                </div>
              )} */}
              {item?.order_detail?.takeaway_details && (
                <div className="modal-details-sec ">
                  <label className="details-label">PickUp By:</label>
                  <div className="details-value">
                    {item?.order_detail?.takeaway_details?.take_by_name} -
                    {item?.order_detail?.takeaway_details?.take_by_mobile}
                  </div>
                </div>
              )}
              {item?.on_trip_associate?.note && (
                <div className="modal-details-sec ">
                  <label className="details-label">NOTE:</label>
                  <div className="details-value">
                    {item?.on_trip_associate?.note}
                  </div>
                </div>
              )}
              {item?.order_detail?.service_type === "delivery" &&
                item?.on_trip_associate && (
                  <div className="modal-details-sec f-c">
                    <label className="details-label">
                      <b>ASSIGNED TO : </b>
                      {item?.on_trip_associate?.associate?.name
                        ? item?.on_trip_associate?.associate?.name
                        : item?.on_trip_associate?.deliver_by}
                    </label>
                    <div className="details-value">
                      <b>ASSIGNED AT: </b>
                      {new Date(
                        item?.on_trip_associate?.created_at
                      ).toDateString() +
                        " " +
                        new Date(
                          item?.on_trip_associate?.created_at
                        ).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                    </div>
                  </div>
                )}
            </div>
            <div className="details-div f-d-r">
              {item?.order_detail?.source_type !== "offsite" && (
                <div className="modal-details-sec ">
                  <label className="details-label">Total:</label>
                  <div className="details-value">
                    {currencySymbol} {item?.calculatedData?.totalAmount}
                  </div>
                </div>
              )}
              {item?.calculatedData?.delivery_charge ? (
                <div className="modal-details-sec f-c">
                  <label className="details-label">Delivery Charges:</label>
                  <div className="details-value">
                    {currencySymbol} {item?.calculatedData?.delivery_charge}
                  </div>
                </div>
              ) : null}
              <div className="modal-details-sec f-c">
                <label className="details-label">DISCOUNT:</label>
                <div className="details-value">
                  {item?.calculatedData?.discount?.length
                    ? currencySymbol
                    : null}
                  {item?.calculatedData?.discount?.length
                    ? item?.calculatedData?.discount.map((d) => (
                        <span key={d.id}>
                          {d?.amount} ( {d?.code} : {d?.desc} ) <br></br>
                        </span>
                      ))
                    : "0"}
                </div>
              </div>
              <div className="modal-details-sec f-c">
                <label className="details-label">TOTAL TAX :</label>
                <div className="details-value">
                  {currencySymbol} {item?.calculatedData?.tax}
                </div>
              </div>
              <div className="modal-details-sec f-c">
                <label className="details-label color-grey">
                  AMOUNT PAYABLE :
                </label>
                <div className="details-value color-grey">
                  {currencySymbol} {item?.calculatedData?.amountWithdelivery_charge}
                </div>
              </div>
            </div>
            <div className="details-div f-d-r">
              <div className="modal-details-sec ">
                <label className="details-label">MOBILE NUMBER :</label>
                <div className="details-value">{item?.guest?.mobile}</div>
              </div>
              {item?.request_client_device ?(
                <div className="modal-details-sec f-c">
                    <label className="details-label">Ordering Device</label>
                    <div className="details-value">
                      {item.request_client_device || 'N.A.'}
                    </div>
                </div>
                ):''}
              {/* {(item.hotel_id != '166' || item.hotel_id != '635') && <div className="modal-details-sec f-c">
                <label className="details-label">MEMBERSHIP NUMBER: </label>
                <div className="details-value">
                  <b> </b>
                  {item?.membership_no ? item?.membership_no : "--"}
                </div>
              </div>} */}
              {item?.order_detail?.check_no ? (
                <div className="modal-details-sec f-c">
                  <label className="details-label">CHECK NUMBER: </label>
                  <div className="details-value">
                    <b> </b>
                    {item?.order_detail?.check_no || "--"}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="details-div f-d-r">
              <div className="modal-details-sec order-item">
                <label className="details-label">ORDER ITEMS</label>
                <div className="details-value">
                {item.order_menu_items.length > 0 &&
                  item.order_menu_items.map((itemEl) => {
                    return (
                      <div className="item-card" key={itemEl.id}>
                        <div className="item-details">
                          <p>
                            {itemEl?.quantity} - {itemEl?.item_name} = {currencySymbol} {itemEl?.price} * {itemEl?.quantity} = {currencySymbol} {itemEl?.price * itemEl?.quantity}
                          </p>
                        </div>
                        {itemEl?.description && <div className="item-instructions-block">
                            {`Instructions - ${itemEl.description || 'N/A'}`}
                        </div>}
                        {itemEl.order_addons.length > 0 && (
                          <div className="addons-block">
                            {itemEl.order_addons.map((itemAdd) => {
                              return (
                                <div className="addon-item" key={itemAdd.id}>
                                  <ul style={{marginLeft: '1rem'}}>
                                    <li>
                                    {itemAdd.item?.item_subaddon?.name}: {itemAdd.quantity} - {itemAdd.item?.name} - {currencySymbol} {itemAdd.item?.price}
                                    </li>
                                  </ul>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>
              </div>
            </div>
            <div className="details-div f-d-r">
              <div className="modal-details-sec">
                <label className="details-label">ORDER INSTRUCTIONS:</label>
                <div className="details-value">
                <pre>{item?.description || "--"}</pre>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default OrderDetailsPopup;
