import React, { useState, useEffect } from 'react';
import AcceptedIrdOrderComponent from './AcceptedIrdOrder';
import ClearOrderComponent from './ClearOrder';
import NewIrdOrderComponent from './NewIrdOrder';
import PunchedIrdOrderComponent from './PunchedIrdOrder';
import {
    Tabs,
    Badge,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
} from '@chakra-ui/react';
import { SearchOrder } from '../common/SearchOrder';
import axios from 'axios';
import { fetchOrderList, irdSelector } from '../../store/slices/ird/irdSlice';
import { useSelector, useDispatch } from 'react-redux';
import { commonSelector } from '../../store/slices/common/commonSlice';
import {
    getCurrencySymbol,
    groupByOrdersByStatus,
} from '../../helper/_commonHelper';

function getNewDate() {
    let tDate = new Date().toLocaleDateString().split('/');
    let newDateToday = `${tDate[2]}-${tDate[1]}-${tDate[0]}`;
    return newDateToday;
}

const Ird = () => {
    let formatedDate = getNewDate();
    const [revenueStartDate, setRevenueStartDate] = useState(formatedDate);
    const [revenueEndDate, setrevenueEndDate] = useState(formatedDate);
    const [revenueLoading, setRevenueLoading] = useState(false);
    const [revenueData, setRevenueData] = useState({});
    const { orderList, loading } = useSelector(irdSelector);
    const { userProfile } = useSelector(commonSelector);
    const dispatch = useDispatch();
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        dispatch(fetchOrderList());
    }, []);
    useEffect(() => {
        handleGenerateRevenue();
    }, []);

    const handleClearDate = () => {
        let currentdate = getNewDate();
        setRevenueStartDate(currentdate);
        setrevenueEndDate(currentdate);
    };
    const handleGenerateRevenue = async () => {
        setRevenueLoading(true);
        try {
            let response = await axios.get(
                `/outlet/order/revenue?start_date=${revenueStartDate}&end_date=${revenueEndDate}`
            );
            if (response.status === 200) {
                const data = response.data.data || [];
                if (data.length) {
                    setRevenueData(data[0]);
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setRevenueLoading(false);
        }
    };
    const tabChangeClickHandler = () => {
        dispatch(fetchOrderList());
    };

    const inputHandleChange = (e) => {
        setSearchValue(e.target.value);
    };

    let updatedOrderList = searchValue
        ? orderList.filter((item) => String(item.id).includes(searchValue))
        : orderList;

    const orderData = groupByOrdersByStatus(updatedOrderList);
    const hotelCurrencyCode = userProfile.hotel?.country?.currency_code || '';
    const currencySymbol = getCurrencySymbol(hotelCurrencyCode);
    return (
        <main className='status-tabs revenue_wrapper'>
            <div className='aside-right'>
                <Tabs>
                    <div className='searchbar-tabs-container'>
                        <TabList>
                            <Tab onClick={() => tabChangeClickHandler()}>
                                New Orders
                                <Badge
                                    variant='solid'
                                    colorScheme='green'
                                    ml='2'
                                >
                                    {orderData?.new_order?.length || 0}{' '}
                                </Badge>
                            </Tab>
                            <Tab onClick={() => tabChangeClickHandler()}>
                                Accepted
                                <Badge
                                    variant='solid'
                                    colorScheme='green'
                                    ml='2'
                                >
                                    {orderData?.accepted?.length || 0}
                                </Badge>
                            </Tab>
                            <Tab onClick={() => tabChangeClickHandler()}>
                                In Kitchen
                                <Badge
                                    variant='solid'
                                    colorScheme='green'
                                    ml='2'
                                >
                                    {orderData?.punchedin?.length || 0}
                                </Badge>
                            </Tab>
                            <Tab onClick={() => tabChangeClickHandler()}>
                                Dispatched/Ready Orders
                                <Badge
                                    variant='solid'
                                    colorScheme='green'
                                    ml='2'
                                >
                                    {orderData?.dispatched?.length || 0}
                                </Badge>
                            </Tab>
                        </TabList>
                        <div className='searchOrder'>
                            <SearchOrder
                                inputHandleChange={inputHandleChange}
                                searchValue={searchValue}
                            />
                        </div>
                    </div>

                    <TabPanels>
                        <TabPanel>
                            <NewIrdOrderComponent
                                data={orderData.new_order || []}
                                loading={loading}
                            />
                        </TabPanel>
                        <TabPanel>
                            <AcceptedIrdOrderComponent
                                data={orderData.accepted || []}
                                loading={loading}
                            />
                        </TabPanel>
                        <TabPanel>
                            <PunchedIrdOrderComponent
                                data={orderData.punchedin || []}
                                loading={loading}
                            />
                        </TabPanel>
                        <TabPanel>
                            <ClearOrderComponent
                                data={orderData.dispatched || []}
                                loading={loading}
                            />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </div>
            <div className='aside-left'>
                <div className='revenue_title'>Revenue</div>
                <div className='revenue_main'>
                    <div className='dates_wrapper'>
                        <div className='form_group'>
                            <label htmlFor='start_date'>Start Date:</label>
                            <input
                                type='date'
                                id='start_date'
                                name='start_date'
                                value={revenueStartDate}
                                onChange={(e) =>
                                    setRevenueStartDate(e.target.value)
                                }
                            />
                        </div>
                        <div className='form_group'>
                            <label htmlFor='end_date'>End Date:</label>
                            <input
                                type='date'
                                id='end_date'
                                name='end_date'
                                value={revenueEndDate}
                                onChange={(e) =>
                                    setrevenueEndDate(e.target.value)
                                }
                            />
                        </div>
                        <div className='form_group'>
                            <div style={{ display: 'flex' }}>
                                <button
                                    className='btn btn-primary'
                                    onClick={handleGenerateRevenue}
                                >
                                    Generate Revenue
                                </button>
                                <button
                                    className='btn btn-submit'
                                    onClick={handleClearDate}
                                >
                                    Clear Date
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='result_revenue'>
                        <div className='result_title'>
                            {revenueLoading ? (
                                'loading...'
                            ) : (
                                <div>
                                    Your Total Orders:{' '}
                                    <span>
                                        {revenueData?.total_orders || 0}
                                    </span>
                                    Your Total Revenue:{' '}
                                    <span>
                                        {currencySymbol}{' '}
                                        {revenueData?.total_revenue?.toLocaleString() ||
                                            0}
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default Ird;
