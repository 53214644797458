import axios from "axios";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { commonSelector } from "../../store/slices/common/commonSlice";
import Counter from "./Counter";
import Loading from "./Loading";

Modal.setAppElement("body");

const AddSocialOrder = (props) => {
  const { userProfile } = useSelector(commonSelector);
  const [orderReceivedDate, setOrderReceivedDate] = useState("");
  const [orderReceivedTime, setOrderReceivedTime] = useState("");
  const [validationErros, setValidationErros] = useState([]);
  const [fieldData, setFieldData] = useState({
    outlet_id: userProfile?.outlet_id,
    service_type: "delivery",
    address: "",
    description: "",
    items: [],
    opt_in_marketing: true,
    membership_no: "",
    user_first_name: "",
    user_last_name: "",
    user_mobile: "",
    received_at: "",
    source_name: "",
    amount: "",
  });
  const [mendatoryFields] = useState([
    "outlet_id",
    "service_type",
    "address",
    "items",
    "user_first_name",
    "user_last_name",
    "user_mobile",
    "received_at",
    "source_name",
    "amount",
  ]);
  const [itemsList, setItemsList] = useState([]);
  const [itemSearch, setItemSearch] = useState("");
  const [loadingItems, setloadingItems] = useState(false);

  const { open, close } = props;

  useEffect(() => {
    fetchItems();
  }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name && value) {
      setFieldData({ ...fieldData, [name]: value });
    }
  };

  const fetchItems = async () => {
    setloadingItems(true);

    try {
      let response = await axios.get(`/outlet/menu-items`);
      if (response.status === 200) {
        const data = response.data.data || [];
        if (data && data.length) {
          let updatedData = data?.map((item) => ({
            ...item,
            isChecked: false,
            qty: 0,
          }));
          setItemsList(updatedData);
        }
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setloadingItems(false);
    }
  };

  const ValidateFields = (formData) => {
    let error = [];
    mendatoryFields.forEach((item) => {
      if (item === "items") {
        let parsedData = JSON.parse(formData.items);
        if (!parsedData.length) {
          error.push(`At least 1 item is required`);
        }
      } else if (item === "received_at") {
        if (!orderReceivedDate) {
          error.push(`Order Date is required`);
        }
        if (!orderReceivedTime) {
          error.push(`Order Time is required`);
        }
      } else if (item === "source_name") {
        if (
          formData["source_name"] === "other" &&
          !formData["source_name_other"]
        ) {
          error.push(`Other source name is required`);
        }
      } else if (!formData[item]) {
        error.push(`${item} is required`);
      }
    });
    return error;
  };
  const addOrder = async () => {
    try {
      let selectedItems = itemsList
        .filter((item) => Boolean(item.isChecked))
        .map((item) => ({
          menu_item_id: item.id,
          quantity: item.qty,
        }));
      let data = {
        ...fieldData,
        received_at: `${orderReceivedDate} ${orderReceivedTime}:00`,
        items: JSON.stringify(selectedItems),
      };

      const isALlOK = ValidateFields(data);
      if (isALlOK.length) {
        setValidationErros(isALlOK);
        setTimeout(() => {
          setValidationErros([]);
        }, 4000);
        return;
      }

      const response = await axios.post(`/outlet/order`, {
        ...data,
      });
      if (response.status === 201) {
        close();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDeepCopy = (items) => {
    let data = [];
    if (items.length) {
      data = JSON.parse(JSON.stringify(items));
    }
    return data;
  };
  const itemClickHandler = (itemID) => {
    let clonedItems = getDeepCopy(itemsList);
    let res = clonedItems.map((item) => {
      if (item.id === itemID) {
        return { ...item, isChecked: true, qty: 1 };
      } else {
        return item;
      }
    });
    setItemsList(res);
  };

  const increaseQuantity = (itemID, qty) => {
    let clonedItems = getDeepCopy(itemsList);
    let res = clonedItems.map((item) => {
      if (item.id === itemID) {
        return { ...item, isChecked: true, qty: qty + 1 };
      } else {
        return item;
      }
    });
    setItemsList(res);
  };
  const decreaseQuantity = (itemID, qty) => {
    let clonedItems = getDeepCopy(itemsList);
    let res = clonedItems.map((item) => {
      if (item.id === itemID) {
        if (qty === 1) {
          return { ...item, isChecked: false, qty: 0 };
        } else {
          return { ...item, isChecked: true, qty: qty - 1 };
        }
      } else {
        return item;
      }
    });
    setItemsList(res);
  };

  return (
    <Modal
      isOpen={open}
      onRequestClose={close}
      className={"modal-dialog modal-lg modal-add-social-order"}
      overlayClassName={"modal-backdrop overflow-auto "}
      contentLabel="Add social Order"
    >
      <div className="modal-content" aria-modal="true" id="modal-content-block">
        <div className="modal-header">
          <div className="title">Add Social Order</div>
          <button className="btn btn-icon" onClick={close}>
            <i className="fa fa-times"></i>
          </button>
        </div>

        <div className="modal-body" id="print-details">
          {validationErros.length ? (
            <div className="row">
              <div className="col-md-12">
                <div className="form_group">
                  {validationErros.map((error, index) => (
                    <div
                      key={index}
                      className="label "
                      style={{ color: "red", fontSize: "10px" }}
                    >
                      <i className="fa fa-info-circle" aria-hidden="true"></i>{" "}
                      {error}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="row">
            <div className="col-md-4">
              <div className="form_group">
                <label className="label">First Name:</label>
                <input
                  type="text"
                  name="user_first_name"
                  placeholder="Enter first name"
                  className="form-control"
                  onChange={(e) => handleChange(e)}
                  required
                />
              </div>
            </div>{" "}
            <div className="col-md-4">
              <div className="form_group">
                <label className="label">Last Name:</label>
                <input
                  type="text"
                  name="user_last_name"
                  placeholder="Enter last name"
                  className="form-control"
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>{" "}
            <div className="col-md-4">
              <div className="form_group">
                <label className="label">Mobile No:</label>
                <input
                  type="text"
                  name="user_mobile"
                  placeholder="Enter mobile no."
                  className="form-control"
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>{" "}
            <div className="col-md-4">
              <div className="form_group">
                <label className="label">Order Received Date:</label>
                <input
                  type="date"
                  id="searchtext"
                  name="searchtext"
                  placeholder="Select Date"
                  className="form-control"
                  onChange={(e) => setOrderReceivedDate(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form_group">
                <label className="label">Order Received Time:</label>
                <input
                  type="time"
                  id="searchtext"
                  name="searchtext"
                  placeholder="Select Time"
                  className="form-control"
                  onChange={(e) => setOrderReceivedTime(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form_group">
                <label className="label">Membership Number (optional) </label>
                <input
                  type="text"
                  name="membership_no"
                  placeholder="Enter your membership No. (if any)"
                  className="form-control"
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-6">
                  <div className="form_group">
                    <label className="label">Source: </label>
                    <select
                      name="source_name"
                      className="form-control"
                      onChange={(e) => handleChange(e)}
                      value={fieldData.source_name}
                    >
                      <option className="option" value="">
                        --Select Option --
                      </option>
                      <option className="option" value="facebook">
                        Facebook
                      </option>
                      <option className="option" value="instagram">
                        Instagram
                      </option>
                      <option className="option" value="linkedin">
                        Linkedin
                      </option>
                      <option className="option" value="twitter">
                        Twitter
                      </option>
                      <option className="option" value="zomato">
                        Zomato
                      </option>
                      <option className="option" value="swiggy">
                        Swiggy
                      </option>
                      <option className="option" value="other">
                        Other
                      </option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form_group">
                    <label className="label">Amount: </label>
                    <input
                      type="number"
                      name="amount"
                      placeholder="Enter amount in INR"
                      className="form-control"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                {fieldData.source_name === "other" && (
                  <div className="col-lg-12">
                    <div className="form_group">
                      <label className="label">Other Source Name: </label>
                      <input
                        type="text"
                        name="source_name_other"
                        placeholder="Enter Other Source Name"
                        className="form-control"
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                )}

                <div className="col-lg-12">
                  <div className="form_group">
                    <label className="label">Delivery Address: </label>
                    <textarea
                      type="text"
                      name="address"
                      placeholder="Enter Delivery Address"
                      className="form-control"
                      rows="4"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form_group">
                    <label className="label">Order Instruction: </label>
                    <textarea
                      type="text"
                      name="description"
                      placeholder="Enter Order Instructions"
                      className="form-control"
                      rows="4"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form_group">
                <label className="label">Order Items: </label>
                <input
                  type="text"
                  id="searchtext"
                  name="searchtext"
                  placeholder="Select items"
                  className="form-control"
                  onChange={(e) => setItemSearch(e.target.value)}
                />
              </div>
              <div className="itemslist-container">
                {loadingItems ? <Loading loadingText="loading items..." /> : ""}
                {/* {itemsList.map((item) => {
                  return (
                 
                  );
                })} */}
                <div id="accordion">
                  {itemsList
                    ?.filter((data) => {
                      if (itemSearch) {
                        return data?.name
                          ?.toLowerCase()
                          ?.includes(itemSearch.toLowerCase());
                      } else {
                        return data;
                      }
                    })
                    .map((item, index) => {
                      return (
                        <div
                          className="form_group"
                          id={"heading" + index}
                          key={item.id}
                        >
                          <span
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                            }}
                          >
                            {item.type === "veg" && (
                              <img
                                src="../../assets/images/veg.png"
                                width="16px"
                                alt=""
                                srcSet=""
                              />
                            )}
                            {item.type === "non_veg" && (
                              <img
                                src="../../assets/images/non-veg.png"
                                width="16px"
                                alt=""
                                srcSet=""
                              />
                            )}
                            <label
                              className="label"
                              style={{ marginLeft: "5px" }}
                            >
                              {item.name}{" "}
                            </label>
                          </span>
                          {item.isChecked && item.qty > 0 ? (
                            <Counter
                              increaseCount={() =>
                                increaseQuantity(item.id, item.qty)
                              }
                              decreaseCount={() =>
                                decreaseQuantity(item.id, item.qty)
                              }
                              count={item.qty}
                            />
                          ) : (
                            <button
                              className="btn btn-icon"
                              onClick={() =>
                                itemClickHandler(item.id, item.isChecked)
                              }
                            >
                              <i className="fa fa-plus"></i>
                            </button>
                          )}
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button className="btn btn-primary" onClick={() => addOrder()}>
            Add order
          </button>
        </div>
      </div>
    </Modal>
  );
};
export default AddSocialOrder;
