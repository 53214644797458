import { createSlice } from '@reduxjs/toolkit';
import initialState from './initialState';
import axios from 'axios';
const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        updateDeviceToken(state, { payload }) {
            state.deviceToken = payload;
        },
        updateLoading(state, { payload }) {
            state.loading = payload;
        },
        updateUserProfile(state, { payload }) {
            state.userProfile = payload;
        },

        updateLanguageList(state, { payload }) {
            state.languageList = payload;
        },
    },
});

export const commonSelector = (state) => state.commonSlice;
export const {
    updateLoading,
    updateUserProfile,
    updateDeviceToken,
    updateLanguageList,
} = commonSlice.actions;

export default commonSlice.reducer;

export function fetchUserProfile() {
    return async (dispatch) => {
        try {
            dispatch(updateLoading(true));
            const response = await axios.get('/user/profile');
            if (response.status === 200) {
                const data = response.data.data || [];
                const langList = data.user?.hotel?.languages;
                console.log(langList)
                dispatch(updateLanguageList(langList));
                dispatch(updateUserProfile(data.user || {}));
            }
        } catch (error) {
            console.log(error);
        } finally {
            dispatch(updateLoading(false));
        }
    };
}
