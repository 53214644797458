import axios from "axios";
import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import Dunzo from "../../assets/Dunzo_Logo.png";
import { commonSelector } from "../../store/slices/common/commonSlice";
const AssignModal = (props) => {
  const [assosiateList, setAssosiateList] = useState([]);
  const {
    open,
    close,
    onAssosiateSelection,
    selectedAssosiate,
    saveAssociate,
    setDeliver_by,
    deliver_by,
    assignLoading,
    assignError,
  } = props;
  const { userProfile } = useSelector(commonSelector);

  useEffect(() => {
    getAssosicate();
  }, []);
  const getAssosicate = async () => {
    try {
      let response = await axios.get(`/outlet/associates`);
      if (response.status === 200) {
        const data = response.data.data || [];
        setAssosiateList(data?.filter((x) => x.is_available === 1));
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <Modal
      isOpen={open}
      onRequestClose={close}
      className={"modal-dialog modal-sm modal-asignee"}
      overlayClassName={"modal-backdrop overflow-auto "}
      contentLabel="Example Modal"
    >
      <div className="modal-content" aria-modal="true" id="modal-content-block">
        <div className="modal-header">
          <div className="title">Order Assignment</div>
          <button className="btn btn-icon" onClick={close}>
            <i className="fa fa-times"></i>
          </button>
        </div>

        <div className="modal-body" id="print-details">
          {assignError && (
            <ul style={{ color: "red", padding: "0 1rem 1rem" }}>
              <li>{assignError}</li>
            </ul>
          )}
          {userProfile.hotel?.hotel_config?.is_dunzo_enabled && (
            <div className="form-group" style={{ display: "flex" }}>
              <input
                type="radio"
                id="dunzo"
                name="order_assign"
                value="dunzo"
                onChange={setDeliver_by}
                checked={deliver_by === "dunzo"}
              />{" "}
              <img
                src={Dunzo}
                alt="dunzo"
                style={{ width: "80px", marginLeft: "0.5rem" }}
              />
            </div>
          )}
          <div
            className="form-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              className="radio-wrapper"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <input
                type="radio"
                id="vserve"
                name="order_assign"
                value="vserve"
                onChange={setDeliver_by}
                checked={deliver_by === "vserve"}
              />{" "}
              <label htmlFor="vserve" style={{ marginLeft: "0.5rem" }}>
                vserve
              </label>
            </div>
            {deliver_by === "vserve" && (
              <div
                className="dropdown-wrapper"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  whiteSpace: "nowrap",
                }}
              >
                <label htmlFor="deliveryPerson" style={{ marginRight: "10px" }}>
                  {" "}
                  Assign To :
                </label>{" "}
                <select
                  id="deliveryPerson"
                  className="form-control"
                  value={selectedAssosiate}
                  onChange={onAssosiateSelection}
                >
                  <option value={""} disabled>
                    -- select associate --
                  </option>
                  {assosiateList.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
        </div>
        <div className="modal-footer">
          <button
            // disabled={!deliver_by}
            className="btn"
            onClick={saveAssociate}
            disabled={assignLoading || !selectedAssosiate}
          >
            {assignLoading && <i className="fa fa-spinner fa-spin"></i>}
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AssignModal;
