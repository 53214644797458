import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initialState";
import axios from "axios";
import { getItemDetail } from "../../../helper/_commonHelper";
const irdSlice = createSlice({
  name: "ird",
  initialState,
  reducers: {
    updateLoading(state, { payload }) {
      state.loading = payload;
    },
    updateOrderList(state, { payload }) {
      state.orderList = payload;
    },
    updateOrderHistory(state, { payload }) {
      state.orderhistory = payload;
    },
    updateItemlist(state, { payload }) {
      state.itemList = payload;
    },
    updateMaxPageLimit(state, { payload }) {
      state.maxPageLimit = payload;
    },
    updateTotalOrderCount(state, { payload }) {
      state.totalOrderCount = payload;
    },
    updateRevenueData(state, { payload }) {
      state.revenueData = payload;
      state.revenueOrderList = payload.orders;
    },
    updateAnalyticsData(state, { payload }) {
      state.analyticsData = payload;
    },
    updateActionTakenOnOrderID(state, { payload }) {
      state.actionTakenOnOrderID = payload;
    }
  },
});

export const irdSelector = (state) => state.irdSlice;
export const {
  updateLoading,
  updateOrderList,
  updateOrderHistory,
  updateItemlist,
  updateMaxPageLimit,
  updateTotalOrderCount,
  updateRevenueData,
  updateAnalyticsData,
  updateActionTakenOnOrderID
} = irdSlice.actions;

export default irdSlice.reducer;

export function fetchOrderList() {
  return async (dispatch) => {
    try {
      dispatch(updateLoading(true));
      const response = await axios.get(`outlet/order`);
      if (response.status === 200) {
        const data = response.data.data || [];
        const updatedOrders = data?.map((x) => {
          return {
            ...x,
            calculatedData: getItemDetail(x),
          };
        });
        dispatch(updateOrderList(updatedOrders));
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(updateLoading(false));
    }
  };
}
