import React, { useState } from 'react';
import Timer from '../Timer/timer';
// import { useSelector } from "react-redux";
// import { commonSelector } from "../../store/slices/common/commonSlice";
import { getBlinkClass } from '../../helper/_commonHelper';
// import CancelOrderModal from './CancelOrder';


const OrderCard = (props) => {
    const { item = {} } = props;
    const [driverAcceptedRequest,setDriverAcceptedRequest] = useState(false);

React.useEffect(()=>{
    if(item?.on_trip_associate && item?.on_trip_associate?.status ==='pending'){
        let assignedTime = new Date(item?.on_trip_associate?.updated_at)
        // let timeValue = assignedTime.getTime();
        let add5MinuteTime = assignedTime.setMinutes(assignedTime.getMinutes() +5 );
        if(Date.now() > add5MinuteTime ){
            setDriverAcceptedRequest(true)

        }
    }
},[])
    // const {userProfile } = useSelector(commonSelector);
    return (
        <div
            className={
                'orderCard ' +
                (item?.order_detail?.service_type === 'takeaway'
                    ? 'takeaway'
                    : 'delivery') +
                    (driverAcceptedRequest && item?.on_trip_associate?.status === 'pending' ?' alert-card':'')
            }
        >
            {/* {item?.guest?.mobile_country_code &&
      userProfile?.hotel?.country?.phone_code !=
        item?.guest?.mobile_country_code ? (
        <span className="type-tile preOrder">International Order</span>
      ) : null} */}
            <div className='orderCard-row'>
                {item?.id && <div className='text'>#{item?.id}</div>}
                <div className='text'>
                    {item?.order_detail?.created_at && (
                        <small>
                            {new Date(
                                item.order_detail.created_at
                            ).toDateString()}
                        </small>
                    )}
                    {item?.order_detail?.created_at && (
                        <small>
                            {new Date(
                                item.order_detail.created_at
                            ).toLocaleString('en-US', {
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: true,
                            })}
                        </small>
                    )}
                </div>
            </div>
            <div className='line'></div>
            {/* <div className="orderCard-row TA-c">
        <div className="text left">No of Guest: </div>
        <div className="text">1</div>
      </div> */}
            {item.user_type === 'hotel_admin' && (
                <div className='orderCard-row redbg'>
                    <div className='text left'>Payment Status: </div>
                    <div className='text'>
                        {item.payment_status === 'offline'
                            ? 'Settle Later'
                            : item.payment_status}
                    </div>
                </div>
            )}
            {item?.order_detail?.created_at && (
                <div
                    className={
                        'orderCard-row redbg ' +
                        (item?.order_detail?.service_type === 'takeaway'
                            ? 'takeaway '
                            : 'delivery ') +
                        getBlinkClass(item, props.tabType)
                    }
                >
                    <div className='text left'>
                        {' '}
                        {item?.order_detail?.service_type?.toUpperCase()}:{' '}
                    </div>
                    <div className='text'>
                        <Timer countDown={props.timerTime} />
                    </div>
                </div>
            )}

            {item?.order_menu_items?.length && (
                <div className='orderCard-row'>
                    <div className='text orderedItems'>
                        {item.order_menu_items.map((itemMenu, index) => (
                            <small key={itemMenu.id}>
                                {itemMenu.quantity} - {itemMenu.item?.name}
                                {index == 2 ? '.....' : ''}
                            </small>
                        ))}
                    </div>
                </div>
            )}
            <div className='line'></div>

            {/* <div className="orderCard-row">
        <div className="text">
          <div className="form-group">
            <input type="checkbox" />
            <label>Order Instruction</label>
          </div>
        </div>
      </div> */}

            {item?.order_detail?.service_type != 'takeaway' && (
                <div className='orderCard-row fd-D'>
                    <div className='text text-align-L'>
                        <small>Address:</small>
                        <small>
                            {' '}
                            {`${item?.order_address?.[0]?.address || ''}, ${
                                item?.order_address?.[0]?.city || ''
                            }, ${item?.order_address?.[0]?.state || ''}`}
                        </small>
                    </div>
                </div>
            )}
            <div className='line'></div>
            {/* <div className="orderCard-row fd-D">
        {item?.order_detail?.service_type != "takeaway" ? (
          <div className="text">
            <small>Delivery Time:</small>
            <small> {item?.order_detail?.pickup_slot}</small>
          </div>
        ) : (
          <div className="text">
            <small>PickUp Time:</small>
            <small>{item?.order_detail?.pickup_slot}</small>
          </div>
        )}
      </div> */}
            <div className='orderCard-row '>
                <div className='text'>
                    <small>Order Instructions</small>
                    <small className='description'>
                        <pre>{item.description || '--'}</pre>
                    </small>
                </div>
            </div>
            <div className='line'></div>
            {props.tabType === 'Accepted' ? (
                <div className='orderCard-row check_no'>
                    <div className='text'>
                        <input
                            type='text'
                            placeholder='Enter your check no'
                            className='check_no_input'
                            onChange={(e) =>
                                props.checkNoHandler(e.target.value)
                            }
                        />
                    </div>
                </div>
            ) : null}

            <div className='card_action'>
                <button className='btn btn-link' onClick={props.viewDetails}>
                    + View Details
                </button>
                {props.tabType === 'InKitchen' &&
                item?.order_detail?.service_type === 'delivery' ? (
                    <>
                        {item?.on_trip_associate?.deliver_by === 'dunzo' ? (
                            item?.on_trip_associate?.status === 'pending' ||
                            item?.on_trip_associate?.status === 'accepted' ? (
                                <button
                                    className='btn btn-re-assign'
                                    onClick={props.cancelDunzoRequest}
                                >
                                    Cancel Dunzo request
                                </button>
                            ) : item?.on_trip_associate?.status ===
                              'picked_up' ? (
                                <p className=' btn-re-assign text'>
                                    Order Picked Up
                                </p>
                            ) : Object.keys(item?.on_trip_associate).length ===
                                  0 ||
                              (item?.on_trip_associate?.deliver_by ===
                                  'dunzo' &&
                                  item?.on_trip_associate?.status ===
                                      'not_delivered') ? (
                                <button
                                    className='btn'
                                    onClick={props.associateBtn}
                                >
                                    Assign
                                </button>
                            ) : item?.on_trip_associate?.status ===
                              'on_trip' ? (
                                <button
                                    className='btn'
                                    onClick={props.footerBtn}
                                >
                                    Dispatched
                                </button>
                            ) : null
                        ) : (
                            <>
                                <button
                                    className={
                                        'btn ' +
                                        (item?.on_trip_associate?.associate_id
                                            ? 'btn-re-assign'
                                            : '')
                                    }
                                    onClick={props.associateBtn}
                                >
                                    {item?.on_trip_associate?.associate_id
                                        ? 'Re-Assign'
                                        : 'Assign'}
                                </button>
                                {item?.on_trip_associate?.associate_id ? (
                                    <button
                                        className='btn'
                                        onClick={props.footerBtn}
                                    >
                                        Dispatched
                                    </button>
                                ) : null}
                            </>
                        )}
                    </>
                ) : item?.order_detail?.service_type === 'takeaway' &&
                  props.tabType === 'ClearOrder' ? (
                    <button className='btn' onClick={props.clearOrderBtn}>
                        Picked Up
                    </button>
                ) : (
                    <button className='btn' onClick={props.footerBtn}>
                        {props.btnTitle}
                    </button>
                )}
                {driverAcceptedRequest && item?.on_trip_associate?.status === 'pending' ? <p style={{color:'#fff',fontSize:'16px',textAlign:'center',lineHeight:1}}><small>Delivery Associate did not accept the request yet. Please intimate him manully to accept the order.</small></p>:''}
            </div>
            {/* {userProfile?.is_cancellation_allowed === 1 ? (item.status !== 'dispatched' && <CancelOrderModal id={item.id} status={item.status} />) : null} */}
        </div>
    );
};

export default OrderCard;
