import React, { useEffect, useState } from "react";
import { convertJSONtoFormData } from "../../helper/_commonHelper";
import { Multiselect } from "multiselect-react-dropdown";
import Modal from "react-modal";
import axios from "axios";
import { useToast } from "@chakra-ui/toast";
import AddEditTranslations from "../translations/AddEditTranslations";

const AddEditAddOn = ({
  title,
  data,
  open,
  ids,
  setShow,
  refresh,
  taxesList,
  showDescriptionField
}) => {
  const [formData, setFormData] = useState({
    menu_item_id: ids?.itemId,
    menu_item_subaddon_id: ids?.subAddOnId,
    name: data?.name || "",
    description: data?.description || "",
    priority: data?.priority || 0,
    type: data?.type || "veg",
    thumbnail: undefined,
    price: data?.price || 0,
    max_per_order: data?.max_per_order || 1,
    min_per_order: data?.min_per_order || 0,
    taxes: data?.taxes || [],
    translations: data?.translations || []
    // tags: JSON.parse(data?.tags || "[]")?.join(",")
  });
  const [selectedTaxes, setselectedTaxes] = useState([]);
  const [errors, setError] = useState(null);
  const toast = useToast();
  const [rows, setRows] = useState([]);

  const handleChange = (evt) => {
    let value = null;
    if (evt.target.type === "checkbox") {
      value = evt.target.checked;
    } else if (evt.target.type === "file") {
      value = evt.target.files[0];
    } else {
      value = evt.target.value;
    }
    setFormData({
      ...formData,
      [evt.target.name]: value,
    });
  };

  useEffect(() => {
    setselectedTaxes(taxesList?.filter((x) => data?.taxes?.includes(x)));
  }, [data]);

  useEffect(() => {
    if (ids) {
      getAllTaxes(ids);
    }
  }, [ids]);

  const getAllTaxes = async ({ addOnId }) => {
    if (addOnId) {
      try {
        // to get item without subcategory
        let response = await axios.get(`/outlet/menu/addon/taxes/${addOnId}`);

        if (response.status === 200) {
          const data = response.data.data || [];
          const alltaxList = data?.map(({ tax }) => ({
            id: tax.id,
            name: tax.name,
          }));
          if (alltaxList?.length) {
            const selectedTaxList = taxesList?.filter(({ id: id1 }) =>
              alltaxList.some(({ id: id2 }) => id2 === id1)
            );
            setselectedTaxes(selectedTaxList);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const saveForm = async () => {
    let form = formData;
    if (data?.id) {
      delete form.menu_id;
    }
    if (form?.tags?.includes(",")) {
      form.tags = JSON.stringify(form?.tags?.split(","));
    }

    try {
      let updatedRows = rows.map((row) => ({
        hotel_language_id: row.hotel_language_id,
        name: row.name,
        description: row.description
      }));
      form['translations'] = updatedRows;
      const datatoSend = convertJSONtoFormData(form);
      let response = await axios.post(
        (data?.id
          ? "/outlet/update/menu/category/item/addon"
          : "/outlet/menu/addon") + (data?.id ? "/" + data?.id : ""),
        datatoSend
      );
      if (response.status === 201) {
        const result = response.data || {};
        showToast("Addon Created!", result?.message);
        setShow(false);
        refresh();
      } else if (response.status === 202) {
        const result = response.data || {};
        showToast("Addon Updated!", result?.message);
        setShow(false);
        refresh();
      } else if (response.status === 400) {
        const result = response.data || {};
        const keys = Object.keys(result?.message);
        let errs = keys?.map((key) => {
          return {
            field: key,
            error: result?.message?.[key]?.[0],
          };
        });
        setError(errs);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const submit = async () => {
    await saveForm();
  };

  const onSelect = (selectedList) => {
    setFormData({
      ...formData,
      taxes: selectedList?.map((x) => x.id),
    });
    setselectedTaxes(selectedList);
  };

  const onRemove = (selectedList, removedItem) => {
    setFormData({
      ...formData,
      taxes: selectedList
        ?.filter((e) => e.id !== removedItem?.id)
        ?.map((x) => x.id),
    });
    setselectedTaxes(selectedList);
  };
  const showToast = (title, description) => {
    toast({
      title: title || "Menu Updated!",
      description:
        `${description}!` || "Menu change has been done successfully!!",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };
  return (
    <Modal
      isOpen={open}
      onRequestClose={() => setShow(false)}
      className={"modal-dialog modal-lg modal-order-details"}
      overlayClassName={"modal-backdrop overflow-auto "}
      contentLabel="Example Modal"
    >
      <div className="modal-content" aria-modal="true" id="modal-content-block">
        <div className="modal-header">
          <div className="title">
            {" "}
            {data?.id ? "Edit " + title : "Add " + title}
          </div>

          <button className="btn btn-icon" onClick={() => setShow(false)}>
            <i className="fa fa-times"></i>
          </button>
        </div>
        <div className="modal-body">
          {/* FORM */}
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
          </div>
          {/* <div className="form-group">
              <label htmlFor="exampleInputEmail1">Description</label>
              <input
                type="text"
                className="form-control"
                placeholder="Description"
                name="description"
                value={formData.description}
                onChange={handleChange}
              />
            </div> */}
          {/* <div className="form-group">
              <label htmlFor="exampleInputEmail1">Type</label>
              <select
                className="form-control"
                value={formData.type}
                onChange={(e) => handleChange(e)}
                name="type"
              >
                <option  value={'veg'}>
                    Vegeterian
                  </option>
                  <option value={'non_veg'}>
                  Non Vegeterian
                  </option>
                  <option value={'alcohol'}>
                  Alcohol
                  </option>
                  <option value={'non_alcohol_bevrages'}>
                  Non Alcohol
                  </option>
              </select>
            </div> */}
          {/* {
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Thumbnail</label>
                <input
                  type="file"
                  className="form-control"
                  placeholder="thumbnail"
                  name="thumbnail"
                  // value={formData.image}
                  onChange={handleChange}
                />
              </div>
            } */}
          {/* <div className="form-group">
              <label htmlFor="exampleInputEmail1">Tags</label>
              <input
                type="text"
                className="form-control"
                placeholder="tags"
                name="tags"
                value={formData.tags}
                onChange={handleChange}
              />
            </div> */}
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">
              Price - leave blank if not applicable. This price will be added
              with the price of item.
            </label>
            <input
              type="number"
              className="form-control"
              placeholder="Price"
              name="price"
              value={formData.price}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Priority</label>
            <input
              type="number"
              className="form-control"
              placeholder="priority"
              name="priority"
              value={formData.priority}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Maximum Per Order</label>
            <input
              type="number"
              className="form-control"
              placeholder="max_per_order"
              name="max_per_order"
              value={formData.max_per_order}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Minimum Per Order</label>
            <input
              type="number"
              className="form-control"
              placeholder="min_per_order"
              name="min_per_order"
              value={formData.min_per_order}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Taxes</label>
            {/* <input
                type="text"
                className="form-control"
                placeholder="taxes"
                name="taxes"
                value={formData.taxes}
                onChange={handleChange}
              /> */}
            <Multiselect
              options={taxesList} // Options to display in the dropdown
              selectedValues={selectedTaxes} // Preselected value to persist in dropdown
              onSelect={onSelect} // Function will trigger on select event
              onRemove={onRemove} // Function will trigger on remove event
              displayValue="name" // Property name to display in the dropdown options
            />
          </div>

          <div className='form-group'>
            <label htmlFor='xampleInputEmail1'>Translations</label>
            <AddEditTranslations
              translations={formData.translations}
              rows={rows}
              setRows={setRows}
              showDescriptionField={showDescriptionField}
            />
          </div>

          <div className="text-danger">
            {errors &&
              errors.map((err) => (
                <>
                  {err?.field?.toUpperCase()} : {err?.error}
                </>
              ))}
          </div>
        </div>

        <div className="modal-footer">
          <button className="btn" onClick={() => setShow(false)}>
            Close
          </button>
          <button className="btn btn-primary" onClick={() => submit()}>
            Save changes
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AddEditAddOn;
