import axios from "axios";
import { deleteCookie, getCookie } from "../helper/_cookieHelper";

export const getOrderSource = () => {
  const userType = localStorage.getItem("user_type");
  if (userType === "hotel_admin") {
    return "ird";
  } else if (userType === "restaurant_manager") {
    return "restaurant";
  } else {
    return "ird";
  }
};
export const getToken = () => {
  let token = getCookie("token");
  return token;
};
//  axio header setup
axios.defaults.baseURL =
  process.env.REACT_APP_NODE_ENV === "production"
    ? `https://vserve-api.valet2you.in/api/v5.0.1/`
    : `https://test-vserve-api.valet2you.in/api/v5.0.1/`;

axios.defaults.headers.common["Accept"] = "application/json, text/plain, */*";
axios.defaults.headers.post["Content-Type"] = "application/json";

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    const token = getToken();
    // Do something before request is sent
    if (token) {
      config.headers.common["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  function (error) {
    console.log(error.response);
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const { response } = error;
    const { request, ...errorObject } = response;
    console.log(request);
    if (errorObject.status === 401) {
      deleteCookie("token");
      window.location.replace("/");
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);
export const deleteAPI = async (url) => {
  let response = await axios.delete("/outlet/" + url);
  return response;
};

export const getTaxes = async () => {
  return axios.get("/common/order/hotel/taxes");
};

export const loginUser = (data) => {
  return axios.post(`login`, data);
};
export const getIRDOrders = () => {
  let orderSource = getOrderSource();
  return axios.get(`${orderSource}/order`);
};

export const getAllOrders = () => {
  return axios.get(`outlet/order`);
};
export const getLastOrderIds = () => {
  return axios.get(`hotel/last-orders`);
};

export const createAllergens = (payload, id) => {
  if (id) {
      return axios.put(`outlet/allergen/update/${id}`, payload);
  }
  return axios.post('outlet/allergen', payload);
};