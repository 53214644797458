import React, { useEffect, useState } from "react";
import { convertJSONtoFormData } from "../../helper/_commonHelper";
import { Multiselect } from "multiselect-react-dropdown";
import Modal from "react-modal";
import { useToast } from "@chakra-ui/toast";
import Loading from '../common/Loading';
import { Flex } from "@chakra-ui/layout";
import { Tag } from "@chakra-ui/tag";
import AddEditTranslations from "../translations/AddEditTranslations";
import axios from "axios";
import CreateDescription from "./masterTextEditor";
import Select from "react-dropdown-select";


const AddEditItem = ({
  title,
  data,
  ids,
  open,
  setShow,
  refresh,
  taxesList,
  showDescriptionField
}) => {
  const [selectedTaxes, setselectedTaxes] = useState(null);
  const [errors, setError] = useState(null);
  const [multitags, setmultitags] = useState([]);
  const [tagList, settagList] = useState([]);
  const toast = useToast();
  const moodAllergens = data?.allergens?.map((item) => item.id) || [];
  const [loading, setLoading] = useState(false);

  console.log(moodAllergens);


  useEffect(() => {
    if (data?.tags) {
      const taglist = JSON.parse(data?.tags);
      const optionList = taglist?.map((tag) => ({ label: tag, value: tag }));
      settagList(optionList);
      setmultitags(optionList);
    }
  }, [data?.tags]);

  const getAllTaxes = async ({ itemId }) => {
    if (itemId) {
      try {
        // to get item without subcategory
        let response = await axios.get(`/outlet/menu/item/taxes/${itemId}`);

        // subcategories data
        if (response.status === 200) {
          const taxList = response.data.data || [];
          const alltaxList = taxList?.map(({ tax }) => ({
            id: tax.id,
            name: tax.name,
          }));
          if (alltaxList?.length) {
            const selectedTaxList = taxesList?.filter(({ id: id1 }) =>
              alltaxList.some(({ id: id2 }) => id2 === id1)
            );
            setselectedTaxes(selectedTaxList);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const [formData, setFormData] = useState({
    menu_id: ids?.menuId,
    menu_category_id: ids?.categoryId,
    menu_sub_category_id: ids?.subCategoryId,
    name: data?.name || "",
    description: data?.description,
    type: data?.type || "veg",
    thumbnail: data?.thumbnail || undefined,
    priority: data?.priority || 0,
    display_type: data?.display_type || "no_thumbnail",
    price: data?.price || 0,
    max_per_order: data?.max_per_order || 10,
    min_per_order: data?.min_per_order || 0,
    allergens: moodAllergens ?? [],
    taxes: data?.taxes || [],
    tags: multitags || [],
    translations: data?.translations || [],
  });

  console.log(moodAllergens);

  const [allergensList, setAllergensList] = useState([]);

  const [rows,setRows]=useState([]);

  useEffect(() => {
    fetchAllergens();
  }, [])

  const fetchAllergens = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `/outlet/allergens`
      );
      console.log(response);
      if (response.status === 200) {
        const data = response.data.data || [];
        let modifiedAllergensList = data.map((item) => ({
          ...item,
          label: item.name,
          value: item.id,
        }));

        modifiedAllergensList = modifiedAllergensList.filter((item) => item.enabled === 1);
        console.log(modifiedAllergensList);
        setAllergensList(modifiedAllergensList);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };



  useEffect(() => {
    setFormData({
      ...formData,
      tags: JSON.stringify(multitags?.map((x) => x.value)),
    });
  }, [multitags]);

  useEffect(() => {
    if (ids) {
      getAllTaxes(ids);
    }
  }, [ids]);
  const handleChange = (evt) => {
    let value = null;
    if (evt.target.type === "checkbox") {
      value = evt.target.checked;
    } else if (evt.target.type === "file") {
      value = evt.target.files[0];
    } else {
      value = evt.target.value;
    }
    setFormData({
      ...formData,
      [evt.target.name]: value,
    });
  };

  const saveForm = async () => {
    if (data?.id) {
      if (formData.thumbnail && typeof formData.thumbnail !== "object") {
        delete formData?.thumbnail;
      }
    }

    try {
      let updatedRows = rows.map((row) => ({
        hotel_language_id: row.hotel_language_id,
        name: row.name,
        description: row.description
      }));
      formData['translations'] = updatedRows;
      const datatoSend = convertJSONtoFormData(formData);
      let response = await axios.post(
        (data?.id ? "/outlet/update/menu/category/item" : "/outlet/menu/item") +
        (data?.id ? "/" + data?.id : ""),
        datatoSend
      );

      if (response.status === 201) {
        const result = response.data || {};
        showToast("Item Created!", result?.message);
        setShow(false);
        refresh();
      } else if (response.status === 202) {
        const result = response.data || {};
        showToast("Item Updated!", result?.message);
        setShow(false);
        refresh();
      } else if (response.status === 400) {
        const result = response.data || {};
        const keys = Object.keys(result?.message);
        let errs = keys?.map((key) => {
          return {
            field: key,
            error: result?.message?.[key]?.[0],
          };
        });
        setError(errs);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const submit = async () => {
    await saveForm();
  };

  const onSelect = (selectedList) => {
    setFormData({
      ...formData,
      taxes: selectedList?.map((x) => x.id),
    });
    setselectedTaxes(selectedList);
  };

  const onRemove = (selectedList, removedItem) => {
    setFormData({
      ...formData,
      taxes: selectedList
        ?.filter((e) => e.id !== removedItem?.id)
        ?.map((x) => x.id),
    });
    setselectedTaxes(selectedList);
  };
  const showToast = (title, description) => {
    toast({
      title: title || "Menu Updated!",
      description:
        `${description}!` || "Menu change has been done successfully!!",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };
  const deleteImage = async () => {
    if (window.confirm("Do you want to delete this image?") == false) {
      return;
    }
    try {
      const payload = {
        entity_type: "menu_item",
        entity_id: data?.id,
      };
      const response = await axios.delete("/image/thumbnail", {
        data: payload,
      });
      if (response.status === 200) {
        setFormData({
          ...formData,
          thumbnail: undefined,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  
  return (
    <Modal
      isOpen={open}
      onRequestClose={() => setShow(false)}
      className={"modal-dialog modal-lg modal-order-details"}
      overlayClassName={"modal-backdrop overflow-auto "}
      contentLabel="Example Modal"
    >
      <div className="modal-content" aria-modal="true" id="modal-content-block">
        {loading && <Loading />}
        <div className="modal-header">
          <div className="title">
            {" "}
            {data?.id ? "Update " + title : "Add " + title}
          </div>

          <button className="btn btn-icon" onClick={() => setShow(false)}>
            <i className="fa fa-times"></i>
          </button>
        </div>

        <div className="modal-body">
          {/* FORM */}
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
          </div>
          <div className="form-group" id="desc">
            <label htmlFor="exampleInputEmail1">Description</label>
            <CreateDescription description={formData.description} onChange={handleChange}/>
          </div>
          {
            <div className="form-group row">
              <div className="col-sm-6">
                <label htmlFor="exampleInputEmail1">Image</label>
                <input
                  type="file"
                  className="form-control"
                  placeholder="thumbnail"
                  name="thumbnail"
                  // value={formData.image}
                  onChange={handleChange}
                />
              </div>
              <div className="col-sm-6">
                {formData?.thumbnail && typeof formData?.thumbnail != "object" && (
                  <div className="image-wrapper">
                    <img
                      src={`https://master-vserve.s3.ap-south-1.amazonaws.com/menu-items/${data.thumbnail}`}
                      alt="category"
                      className="image"
                      style={{
                        width: "210px",
                        height: " 90px",
                        borderRadius: "8px",
                        objectFit: "cover",
                        marginTop: "0.5rem",
                      }}
                    />
                    <button
                      className="btn btn-icon btn-danger"
                      onClick={deleteImage}
                    >
                      <i className="fa fa-trash"></i>
                    </button>
                  </div>
                )}
              </div>
            </div>
          }
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Image Type</label>
            <select
              className="form-control"
              value={formData.display_type}
              onChange={(e) => handleChange(e)}
              name="display_type"
            >
              <option value={"no_thumbnail"}>No thumbnail</option>
              <option value={"small_thumbnail"}>Small thumbnail</option>
              <option value={"big_thumbnail"}>Big thumbnail</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Select Item&apos;s Allergen{' '}
            </label>
            <Flex direction="column" mt={2}>
              <Multiselect
                options={allergensList} // Note: multiselect-react-dropdown uses 'options' instead of 'children'
                displayValue="label"
                onSelect={(selectedOptions) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    allergens: Array.isArray(selectedOptions)
                      ? selectedOptions.map((option) => (option.value ))
                      : [],
                  }))
                }
                onRemove={(selectedOptions) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    allergens: Array.isArray(selectedOptions)
                      ? selectedOptions.map((option) => (option.value))
                      : [],
                  }))
                }
                selectedValues={allergensList.filter((allergen) =>
                  formData.allergens.includes(allergen.value)
                )}
                placeholder="Please select"
              />
              
              <Flex mt={2} flexWrap="wrap">
                {formData.allergens.map((selectedValues) => (
                  <Tag
                    key={selectedValues.value}
                    m={1}
                    size="md"
                    variant="subtle"
                    colorScheme="blue"
                  >
                     {allergensList.find((allergen) => allergen.value === selectedValues)?.label}
                  </Tag>
                ))}
              </Flex>
            </Flex>
          </div>
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Type</label>
            <select
              className="form-control"
              value={formData.type}
              onChange={(e) => handleChange(e)}
              name="type"
            >
              <option value={"veg"}>Vegeterian</option>
              <option value={"non_veg"}>Non Vegeterian</option>
              <option value={"alcohol"}>Alcohol</option>
              <option value={"non_alcohol_bevrages"}>Non Alcohol</option>
            </select>
          </div>
          {/* {
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Thumbnail</label>
                <input
                  type="file"
                  className="form-control"
                  placeholder="thumbnail"
                  name="thumbnail"
                  // value={formData.image}
                  onChange={handleChange}
                />
              </div>
            } */}
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Tags (Add tag and press Enter)</label>
            {/* <input
                type="text"
                className="form-control"
                placeholder="tags"
                name="tags"
                value={formData.tags}
                onChange={handleChange}
              /> */}
            <Select
              multi
              create
              options={tagList}
              values={tagList}
              placeholder="Type and press Enter..."
              addPlaceholder="Type and press Enter..."
              dropdownHandle={false}
              onChange={(value) => {
                setmultitags(value);
              }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Price</label>
            <input
              type="number"
              className="form-control"
              placeholder="Price"
              name="price"
              value={formData.price}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Priority</label>
            <input
              type="number"
              className="form-control"
              placeholder="Priority"
              name="priority"
              value={formData.priority}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">
              Maximun quantity of this item that a guest can order.
            </label>
            <input
              type="number"
              className="form-control"
              placeholder="Choose a number greater than 0"
              name="max_per_order"
              value={formData.max_per_order}
              onChange={handleChange}
            />
          </div>
          {/* <div className="form-group">
              <label htmlFor="exampleInputEmail1">min_per_order</label>
              <input
                type="number"
                className="form-control"
                placeholder="min_per_order"
                name="min_per_order"
                value={formData.min_per_order}
                onChange={handleChange}
              />
            </div> */}

          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Taxes</label>
            {/* <input
                type="text"
                className="form-control"
                placeholder="taxes"
                name="taxes"
                value={formData.taxes}
                onChange={handleChange}
              /> */}

            <Multiselect
              options={taxesList} // Options to display in the dropdown
              selectedValues={selectedTaxes} // Preselected value to persist in dropdown
              onSelect={onSelect} // Function will trigger on select event
              onRemove={onRemove} // Function will trigger on remove event
              displayValue="name" // Property name to display in the dropdown options
            />
          </div>
          {/* <div className="form-group">
              <label htmlFor="exampleInputEmail1">start_time</label>
              <input
                type="time"
                className="form-control"
                placeholder="start_time"
                name="start_time"
                value={formData.start_time}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">end_time</label>
              <input
                type="time"
                className="form-control"
                placeholder="end_time"
                name="end_time"
                value={formData.end_time}translationset
                onChange={handleChange}
              />
            </div> */}

          <div className='form-group'>
            <label htmlFor='xampleInputEmail1'>Translations</label>
            <AddEditTranslations
              translations={formData.translations}
              rows={rows}
              setRows={setRows}
              showDescriptionField={showDescriptionField}
            />
          </div>

          <div className="text-danger">
            {errors &&
              errors.map((err) => (
                <>
                  <div>
                    {err?.field?.toUpperCase()} : {err?.error}
                  </div>
                </>
              ))}
          </div>
        </div>

        <div className="modal-footer">
          <button className="btn" onClick={() => setShow(false)}>
            Close
          </button>
          <button className="btn btn-primary" onClick={() => submit()}>
            Save changes
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AddEditItem;
