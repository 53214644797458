export default {
  loading: false,
  orderList: [],
  orderhistory: [],
  itemList: [],
  maxPageLimit: 0,
  totalOrderCount: 0,
  revenueData: {},
  revenueOrderList: {},
  analyticsData: [],
  actionTakenOnOrderID:null
};
