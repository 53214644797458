import React from "react";
import Loader from "react-loader-spinner";

function Loading(props) {
  const { loadingText } = props;
  return (
    <div className="loading">
      <Loader type="ThreeDots" color="#665551" height={40} width={40} />
      <p>{loadingText || "loading..."}</p>
    </div>
  );
}

export default Loading;
