import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Header from '../pages/Ird/Header';
// import { getLastOrderIds } from '../services/apiService';
import {
    commonSelector,
    fetchUserProfile,
} from '../store/slices/common/commonSlice';
import { useSelector, useDispatch } from 'react-redux';
import { getCookie } from '../helper/_cookieHelper';
import {
    compareAndSave,
    updateLocalOrderByType,
} from '../helper/_commonHelper';
import {
    fetchOrderList,
    irdSelector,
    updateActionTakenOnOrderID,
} from '../store/slices/ird/irdSlice';
import Loading from './common/Loading';
import Pusher from 'pusher-js';

// import { onMessageListener } from "../init-fcm";
import { useToast } from '@chakra-ui/toast';

const Layout = () => {
    const [notificationData, setNotificationData] = useState({});
    const [lastOrderIDs] = useState({});
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const toast = useToast();

    const { loading, deviceToken, userProfile } = useSelector(commonSelector);
    const { actionTakenOnOrderID } = useSelector(irdSelector);
    console.log(deviceToken);

    const audio = new Audio(
        'https://master-vserve.s3.ap-south-1.amazonaws.com/tone/desk_bell.mp3'
    );
    audio.muted = true;

    useEffect(() => {
        const token = getCookie('token');
        if (!token) {
            navigate('/login', { replace: true });
        }
    }, []);

    const triggerNotificationUpdate = (
        orderID,
        actionTakenOnOrderID,
        title,
        msg
    ) => {
        if (orderID != actionTakenOnOrderID) playBell();
        showToast(title, msg);
        dispatch(fetchOrderList());
        dispatch(updateActionTakenOnOrderID(null));
    };

    useEffect(() => {
        dispatch(fetchUserProfile());
    }, []);

    useEffect(() => {
        console.log(userProfile);
        let pusher;
        if (userProfile.outlet && userProfile.outlet.id) {
            // eslint-disable-next-line no-undef
            pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
                cluster: 'ap2',
            });
            const channel = pusher.subscribe(`outlet_${userProfile.outlet.id}`);
            channel.bind('new_order', (data) => {
                let orderID = data.message.order_id || '';
                const title = `You have a New Order`;
                let mesg = `A new order has been received with OrderID #${
                    data.message.order_id ?? ''
                }`;
                triggerNotificationUpdate(
                    orderID,
                    actionTakenOnOrderID,
                    title,
                    mesg
                );
            });
            channel.bind('accept_order', (data) => {
                let orderID = data.message.order_id || '';
                let mesg = `Order has been accepted with OrderID #${
                    orderID ?? ''
                }`;
                const title = `Order Accepted`;

                triggerNotificationUpdate(
                    orderID,
                    actionTakenOnOrderID,
                    title,
                    mesg
                );
            });
            // channel.bind('cancel_order', (data) => {
            //     let orderID = data.message.order_id || '';
            //     let mesg = `Order has been cancelled with OrderID #${
            //         orderID ?? ''
            //     }`;
            //     const title = `Order Cancelled`;

            //     triggerNotificationUpdate(
            //         orderID,
            //         actionTakenOnOrderID,
            //         title,
            //         mesg
            //     );
            // });
            channel.bind('punched_in_order', (data) => {
                let orderID = data.message.order_id || '';
                const title = `Order Punched in POS`;
                let mesg = `Order has been punched with OrderID #${
                    orderID ?? ''
                }`;
                triggerNotificationUpdate(
                    orderID,
                    actionTakenOnOrderID,
                    title,
                    mesg
                );
            });
            channel.bind('dispatch_order', (data) => {
                let orderID = data.message.order_id || '';
                const title = `Order Dispatched`;
                let mesg = `Order has been dispatched with OrderID #${
                    orderID ?? ''
                }`;
                triggerNotificationUpdate(
                    orderID,
                    actionTakenOnOrderID,
                    title,
                    mesg
                );
            });

            channel.bind('clear_order', (data) => {
                let orderID = data.message.order_id || '';
                const title = `Order Delivered and moved to order History`;
                let mesg = `Order has been Delivered and moved to Order History with OrderID #${
                    orderID ?? ''
                }`;
                triggerNotificationUpdate(
                    orderID,
                    actionTakenOnOrderID,
                    title,
                    mesg
                );
            });

            channel.bind('order_assign_associate', (data) => {
                let orderID = data.message.order_id || '';
                const title = `Order assigned to Associate`;
                let mesg = `Order has been Assigned to Associate with OrderID #${
                    orderID ?? ''
                }`;
                triggerNotificationUpdate(
                    orderID,
                    actionTakenOnOrderID,
                    title,
                    mesg
                );
            });

            channel.bind('accept_trip', (data) => {
                let orderID = data.message.order_id || '';
                const title = `Order Accepted by Associate`;
                let mesg = `Order has been accepted by Associate with OrderID #${
                    orderID ?? ''
                }`;
                triggerNotificationUpdate(
                    orderID,
                    actionTakenOnOrderID,
                    title,
                    mesg
                );
            });
            channel.bind('picked_up_trip', (data) => {
                let orderID = data.message.order_id || '';
                const title = `Order picked up by Associate`;
                let mesg = `Order has been picked-up by Associate with OrderID #${
                    orderID ?? ''
                }`;
                triggerNotificationUpdate(
                    orderID,
                    actionTakenOnOrderID,
                    title,
                    mesg
                );
            });
            channel.bind('start_trip', (data) => {
                let orderID = data.message.order_id || '';
                const title = `Trip started by Associate`;
                let mesg = `Trip has been started by Associate with OrderID #${
                    orderID ?? ''
                }`;
                triggerNotificationUpdate(
                    orderID,
                    actionTakenOnOrderID,
                    title,
                    mesg
                );
            });
            channel.bind('complete_trip', (data) => {
                let orderID = data.message.order_id || '';
                const title = `Order Delivered`;
                let mesg = `Order has been deliverd by Associate with OrderID #${
                    orderID ?? ''
                }`;
                triggerNotificationUpdate(
                    orderID,
                    actionTakenOnOrderID,
                    title,
                    mesg
                );
            });
        }
        return () => {
            if (userProfile.outlet && userProfile.outlet.id) {
                pusher.unsubscribe(`outlet_${userProfile.outlet.id}`);
            }
        };
    }, [userProfile, actionTakenOnOrderID]);
    // useEffect(() => {
    //     navigator.serviceWorker.addEventListener('message', onMessageListener);
    //     return () => {
    //         navigator.serviceWorker.removeEventListener(
    //             'message',
    //             onMessageListener
    //         );
    //     };
    // }, []);
    // const showDunzoAlert = (data) => {
    //     window.alert(`Order No: #${data.order_id} -  ${data.message}`);
    //     dispatch(fetchOrderList());
    // };

    // const onMessageListener = (payload) => {
    //     // playBell();
    //     console.log(payload)
    //     const sourceData = payload.data || {};
    //     onNotificationArrived(payload);
    //     if (
    //         sourceData &&
    //         sourceData['firebase-messaging-msg-data'] &&
    //         sourceData['firebase-messaging-msg-data'].data &&
    //         sourceData['firebase-messaging-msg-data'].data.source ===
    //             'dunzo_service'
    //     ) {
    //         showDunzoAlert(sourceData['firebase-messaging-msg-data'].data);
    //     } else {
    //         let orderID =
    //             sourceData['firebase-messaging-msg-data'].data.order_id || '';
    //         let message =
    //             sourceData['firebase-messaging-msg-data'].data.message ||
    //             'Order status has been changed';
    //         if (orderID || message) {
    //             showToast(`Order ID: #${orderID}`, message);
    //         }
    //     }
    // };
    const showToast = (title, description) => {
        toast({
            title: title || 'Order ID',
            description: `${description}`,
            status: 'success',
            duration: 5000,
            isClosable: true,
        });
    };
    // useEffect(() => {
    //     let myInterval = setInterval(fetchLastOrders, 30000);
    //     return () => {
    //         clearInterval(myInterval);
    //     };
    // }, []);

    // const onNotificationArrived = () => {
    //     fetchLastOrders();
    // };
    const updateOrderByType = (type) => {
        dispatch(fetchOrderList());
        updateLocalOrderByType(lastOrderIDs, type);
        triggerBell(lastOrderIDs);
    };

    // const fetchLastOrders = async () => {
    //     try {
    //         let response = await getLastOrderIds();
    //         if (response.status === 200) {
    //             const data = response.data?.data || {};
    //             setLastOrderIDs(data);
    //             triggerBell(data);
    //         }
    //     } catch (error) {
    //         console.error(error);
    //     }
    // };

    const triggerBell = (data) => {
        let hasNewOrders = compareAndSave(data);
        setNotificationData(hasNewOrders);
        if (Object.keys(hasNewOrders).length) {
            // playBell();
            console.log(data);
        }
    };
    const playBell = async () => {
        try {
            if (!audio.paused) {
                audio.muted = true;
                audio.muted = false;
                audio.pause();
                audio.play();
            } else {
                audio.muted = true;
                audio.muted = false;
                audio.play();
            }
        } catch (error) {
            console.log('Can not play the bell!!');
        }
    };

    return (
        <div className='main-layout'>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <Header
                        notificationData={notificationData}
                        updateOrderByType={updateOrderByType}
                    />
                    <Outlet />
                </>
            )}
        </div>
    );
};

export default Layout;
